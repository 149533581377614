import React from 'react'
import classNames from 'classnames'

import { getComponentOptions } from 'global-content'

import { MediaQuery } from 'components/scaffold'
import { Layout } from 'components/layout'

import styles from './header-bar.css'

const defaultHeader = {
  desktop: {
    borderBottom: true,
    container: 'medium',
    height: '75px',
    order: {
      left: ['logo', 'headerLinks'],
      right: ['languageSelector', 'searchBar', 'favoritesButton', 'miniCart']
    }
  },
  tabletMedium: {
    height: '55px',
    order: {
      left: ['menuButton', 'searchBar'],
      center: ['logo'],
      right: ['favoritesButton', 'miniCart']
    }
  }
}

const HeaderBarComponent = ({
  borderBottom,
  container,
  device,
  order,
  height,
  renderOrder
}) => (
  <div
    className={classNames(styles.headerBar, {
      [styles.borderBottom]: borderBottom
    })}
  >
    <Layout
      className={styles.innerWrapper}
      layout={{
        container
      }}
      style={{ height }}
    >
      {renderOrder({ device, order })}
    </Layout>
  </div>
)

export const HeaderBar = ({ renderOrder }) => {
  const { container, layout } = getComponentOptions(`header`)

  return (
    <MediaQuery
      allDevices={{
        container: container,
        renderOrder: renderOrder
      }}
      startingProps={defaultHeader}
      customProps={layout}
    >
      {HeaderBarComponent}
    </MediaQuery>
  )
}
