import {
  LOAD_CHECKOUT_SCHEMA,
  LOAD_SCHEMA
} from 'state/actions'

const initialState = {}

const schemas = (state = initialState, action) => {
  switch (action.type) {
  case `${LOAD_SCHEMA}_PENDING`:
    return state
  case `${LOAD_SCHEMA}_REJECTED`:
    return state
  case `${LOAD_SCHEMA}_FULFILLED`:
    return {
      ...state,
      [action.payload.name]: action.payload.schema
    }
  case `${LOAD_CHECKOUT_SCHEMA}_FULFILLED`:
    return {
      ...state,
      checkout: action.payload
    }
  default:
    return state
  }
}

export default schemas
