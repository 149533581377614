// Checkout related
export const SHOW_TEST_PAYMENTS_PARAM = `showTestPayments`
export const MAX_LINE_ITEMS = 30

// Responsive device keys
export const DESKTOP_KEY = `desktop`
export const DESKTOP_SMALL_KEY = `desktopSmall`
export const TABLET_LARGE_KEY = `tabletLarge`
export const TABLET_MEDIUM_KEY = `tabletMedium`
export const TABLET_SMALL_KEY = `tabletSmall`
export const MOBILE_LARGE_KEY = `mobileLarge`

// Payment brandcodes
export const BRANDCODE_CREDITCARD = `creditcard`
export const BRANDCODE_PAYPAL = `paypal`
export const BRANDCODE_PAYPAL_EXPRESS = `paypal_ecs`
export const BRANDCODE_GOOGLEPAY = `paywithgoogle`
export const BRANDCODE_GOOGLEPAY_EXPRESS = `paywithgoogleExpress`
export const BRANDCODE_APPLEPAY = `applepay`
export const BRANDCODE_APPLEPAY_EXPRESS = `applepayExpress`
export const BRANDCODE_AFTERPAY = `afterpaytouch`
export const BRANDCODE_MOLPAY = `molpay_ebanking_fpx_MY`
export const BRANDCODE_KCP_CREDITCARD = `kcp_creditcard`
export const BRANDCODE_KCP_PAYCO = `kcp_payco`
export const BRANDCODE_KCP_BANKTRANSFER = `kcp_banktransfer`

// Payment order messages statuscodes
export const STATUSCODE_UNAVAILABLE_ITEMS = `UNAVAILABLE_ITEMS`

// PLP
export const HITS_PER_PAGE = 120

// Prodct
export const AVAILABILITY_FLAGS = {
  '-2': `BACKORDER`,
  '-1': `PREORDER`,
  1: `AVAILABLE`,
  2: `LOWSTOCK`,
  3: `OUTOFSTOCK`,
  4: `DISCONTINUED`
}
export const ORDERABLE_STATES = ['AVAILABLE', 'LOWSTOCK', 'PREORDER', 'BACKORDER']
export const SALE_STATES = ['NOSALE', 'ONSALE']
