import React from 'react'
import { useLocation } from 'react-router-dom'

import { useUrlManager } from 'hooks'
import { defaultSearchSort, useFilters } from 'hooks/useFilters'
import { SEARCH_ROUTE } from 'router'
import { SearchInput } from 'components/inputs'

export const SiteSearch = ({
  expandable,
  onSubmit,
  size,
  variation
}) => {
  const { pathname } = useLocation()
  const urlManager = useUrlManager()
  const { applyQuery } = useFilters()

  return (
    <SearchInput
      expandable={expandable}
      key="searchBar"
      onSubmit={onSearchSubmit}
      size={size}
      variation={variation}
    />
  )

  function onSearchSubmit(query) {
    onSubmit && onSubmit()

    if (SEARCH_ROUTE !== pathname) {
      urlManager({
        pathname: {
          value: '/search'
        },
        params: {
          replace: {
            query,
            sortBy: defaultSearchSort
          }
        }
      })
    } else {
      applyQuery(query)
    }
  }
}
