import mergeWith from 'lodash/mergeWith'
import get from 'lodash/get'

import { customizer } from 'utils/mergeCustomizer'

export function setComponents() {
  window.$content.components = mergeWith({}, defaultComponentConfigs, window.$CONFIG.components, customizer)
}

export function getComponentOptions(key) {
  return get(window.$content.components, key)
}

export const defaultComponentConfigs = {
  card: {
    favIconSize: '2rem',
    rankingSize: 'xs',
    showLowStock: false,
    swatchLimit: 5,
    swatchSize: 'small',
    showFavoriteButton: true,
    showPrice: true,
    showRanking: false,
    showSwatches: true,
    showTitle: true,
    textAlign: 'left'
  },
  cartCount: {
    position: 'center',
    size: '3.5rem'
  },
  checkout: {
    showEmailSignUp: false
  },
  favoritesLink: {
    size: '3.2rem'
  },
  filterMenuButton: {
    content: {
      areas: {
        dropdown: {
          fontSize: 'xs',
          open: {
            iconRef: 'angle_up'
          },
          close: {
            iconRef: 'angle_down'
          }
        },
        accordion: {
          fontSize: 'xs',
          open: {
            iconRef: 'angle_up'
          },
          close: {
            iconRef: 'angle_down'
          }
        }
      }
    }
  },
  header: {
    components: {
      search: {
        config: {},
        styling: {
          mobileToggle: {
            iconSize: '3.4rem'
          }
        }
      }
    },
    container: 'medium',
    bannerNav: {
      borderBottom: true
    },
    layout: {},
    config: {
      showIconLabels: false
    },
    menuIconSize: '2.5rem',
    searchInput: {
      size: 'medium'
    }
  },
  languageSelector: {
    styling: {
      borderLeft: false
    }
  },
  linkSet: {
    size: 'xs'
  },
  logo: {},
  productTray: {
    innerLayout: undefined
  },
  megamenu: {
    innerContainer: {
      alignment: null,
      container: 'normal',
      horizontalPadding: true
    },
    order: ['navGroups', 'poster'],
    outerContainer: {
      container: 'normal'
    },
    posterRatio: 'landscapeSpotlightTablet2'
  },
  optionHeader: {
    size: undefined
  },
  paginator: {
    size: 'regular'
  },
  price: {
    showFrom: false
  },
  product: {
    gallery: {
      removeFromBeginningOfGallery: 0
    }
  },
  scrollUp: {
    size: '2.8rem'
  },
  searchInput: {
    styling: {
      border: 'full',
      iconSize: '2.6rem',
      iconPosition: 'right'
    }
  },
  sideNavLink: {
    config: {
      activeState: 1,
      hoverState: 1
    }
  },
  swatch: {
    type: 'square'
  }
}
