import React from 'react'
import Ajv from 'ajv'
import { reducer as formReducer } from 'redux-form'
import validate from 'redux-form-with-ajv'
import { useDispatch, useSelector } from 'react-redux'

import store from 'state/reducers'
import { loadSchema, prerenderReady } from 'state/actions'

import { useDataFetching } from 'hooks/useDataFetching'
import { ContactForm } from './contact-form'

const ContactContainer = () => {
  const folder = `/data`
  const source = `contact-us-form.json`
  const [data] = useDataFetching({ folder, source })

  const dispatch = useDispatch()
  const schema = useSelector(state => state.schemas.contactUs)

  React.useEffect(() => {
    store.reducerManager.add('form', formReducer)
    dispatch(loadSchema('/data/contact-us-schema.json', 'contactUs'))
    dispatch(prerenderReady('[data-testid="contact-form"]'))
  }, [])

  if (schema && data) {
    return (
      <ContactForm
        {...data}
        validate={validate(schema, {
          ajv: customAjv(),
          errorMessage: errorKeyword
        })}
      />
    )
  }

  return null

  // Returns the keyword as the error message
  // <FormField /> handles the actual error message rendering
}

function errorKeyword(error) {
  return error.keyword
}

function customAjv() {
  const ajv = new Ajv({
    allErrors: true,
    verbose: true
  })

  ajv.addKeyword('fileAttachment', {
    validate: function(s, d) {
      const totalFileSize = Array.from(d).reduce((acc, cur) => acc + cur.size, 0)

      if (totalFileSize > 15 * 1000 * 1000) {
        return false
      }

      return true
    }
  })

  return ajv
}

export default ContactContainer
