import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'

// This can't be a utility function and has to be a hook so
// we can use the useHistory hook from react-router-dom
// we can't provide a custom history object to the router then import it
// as basename isn't supported correctly with a custom history

// we have to do updates using react-router's history so all of react-router's
// dependencies are updated correctly

export function useUrlManager() {
  const history = useHistory()
  const location = useLocation()

  return function urlManager({
    method = 'push',
    params = {},
    pathname = {}
  }) {
    const args = {
      pathname: getPathname(location, pathname),
      search: getParams(location, params)
    }

    const methodMap = {
      replace: () => history.replace(args),
      push: () => history.push(args)
    }

    methodMap[method]()
  }
}

// implementation
function getPathname(location, pathname) {
  const existingPathname = location.pathname

  const {
    action = 'replace',
    value = existingPathname
  } = pathname

  const actionMap = {
    append: () => `${existingPathname}${value}`,
    replace: () => value,
    remove: () => existingPathname.replace(value, '')
  }

  return actionMap[action]()
}

function getParams(location, params) {
  const existingParams = queryString.parse(location.search, {
    arrayFormat: 'comma'
  })
  const newParamsObj = constructNewParams(existingParams, params)
  const stringifiedParams = queryString.stringify(newParamsObj, {
    arrayFormat: 'comma'
  })

  return stringifiedParams ? `?${stringifiedParams}` : ''
}

function constructNewParams(existingParams, params) {
  let newParams = { ...existingParams }

  const actionMap = {
    append: (values, collected) => ({ ...collected, ...values }),
    replace: values => values,
    remove: (values, collected) => {
      const clone = { ...collected }
      values.forEach(value => delete clone[value])
      return clone
    }
  }

  Object.keys(params).forEach(action => {
    newParams = actionMap[action](params[action], newParams)
  })

  return newParams
}
