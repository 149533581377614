import React from 'react'

import { Svg } from 'components/icons'
import { Image } from 'components/images'
import { HtmlText } from 'components/text'

import styles from './promotion-additional.css'

export const PromotionAdditional = ({
  content = {},
  styling = {}
}) => {
  const { html, iconRef, imageSrc, text } = content
  const { backgroundColor, borderColor, color } = styling

  const inline = {
    backgroundColor,
    borderColor,
    color
  }

  return (
    <div
      className={styles.container}
      style={inline}
    >
      {iconRef &&
        <div className={styles.iconWrapper}>
          <Svg
            icon={iconRef}
            size="3rem"
          />
        </div>
      }

      {imageSrc &&
        <div className={styles.imageWrapper}>
          <Image src={imageSrc} />
        </div>
      }

      {(html || text) &&
        <HtmlText html={html || text} />
      }
    </div>
  )
}
