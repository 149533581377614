import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { I18n } from 'utils/i18n'
import { updateGoogleAnalytics } from 'services/analytics'
import { SiteSearch } from 'components/inputs'
import { Heading } from 'components/text'

import styles from './zero-state.css'

export const webLabels = {
  title: `WEB.LISTING.NORESULTS.TITLE`,
  subtitle: `WEB.LISTING.NORESULTS.SUBTITLE`
}

export const ZeroState = () => {
  const { pathname, search } = useLocation()
  const { query } = queryString.parse(search)

  React.useEffect(() => {
    if (pathname === '/search') {
      updateGoogleAnalytics(`emptySearch`, {
        query
      })
    }
  }, [pathname, query])

  return (
    <section className={styles.container}>
      <div className={styles.textWrapper}>
        <Heading
          content={{
            html: I18n.t(webLabels.title)
          }}
          styling={{
            fontFamily: `primary`,
            fontSize: `medium`,
            universalColor: true
          }}
        />
        {I18n.t(webLabels.subtitle)}
      </div>
      <SiteSearch variation="zerostate" />
    </section>
  )
}
