import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loadCountries } from 'state/actions'
import { Dropdown } from 'components/form'

export const CountryDropdown = ({ input, meta, name }) => {
  const dispatch = useDispatch()
  const countries = useSelector(state => state.cart.countries)

  React.useEffect(() => {
    dispatch(loadCountries())
  }, [])

  const countryOptions = countries.map(country => ({
    ...country,
    label: country.name,
    value: country.code
  }))

  countryOptions.unshift({
    label: 'Select country',
    value: ''
  })

  return (
    <Dropdown
      meta={meta}
      name={name}
      input={input}
      options={countryOptions}
      useLabelAsValue={false}
    />
  )
}
