import React from 'react'
import classNames from 'classnames'

import { Image } from 'components/images'
import { Svg } from 'components/icons'

import styles from './control-track.css'

export const ControlTrack = ({
  activeItem,
  controlsPosition,
  controlsRatio,
  items,
  onClick
}) => {
  return (
    <div className={classNames(styles.controlTrack, styles[controlsPosition])}>
      {items.map((item, i) => (
        <button
          className={classNames(styles.control, {
            [styles.active]: activeItem === i
          })}
          key={item.data.content.src}
          onClick={() => (onClick(i))}
        >
          {renderItem(item)}
        </button>
      ))}
    </div>
  )

  function renderItem(item) {
    if ([`videoItemCarousel`, `videoItemStacked`].includes(item.data.component)) {
      return (
        <div className={styles.wrapper}>
          <Image
            ratio={controlsRatio}
            interactive
            src={item.data.content.poster}
          />
          <div className={styles.iconWrapper}>
            <Svg
              block
              icon="play"
              size="1.5rem"
            />
          </div>
        </div>
      )
    }

    return (
      <Image
        alt={item.data.content.alt}
        key={item.data.content.src}
        ratio={controlsRatio}
        interactive
        src={item.data.content.src}
      />
    )
  }
}
