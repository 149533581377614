import React from 'react'
import { useSelector } from 'react-redux'

import { I18n } from 'utils/i18n'
import { useFilters } from 'hooks'
import { SelectedOptions, SelectedFilterButton } from 'components/filters'
import { CREATEFILTERSMAP } from 'services/algolia'

export const webLabels = {
  clearAll: `WEB.LISTING.FILTER.CTA.CLEARALL`
}

export const SelectedFilters = () => {
  const selectedFilters = useSelector(state => state.listing.selectedFilters)
  const [validFiltersToShow, totalCount] = getValidFitersToShow(selectedFilters)
  const { clearSelected } = useFilters()

  if (validFiltersToShow.length) {
    return (
      <div>
        {validFiltersToShow.map(filter => (
          <SelectedOptions
            name={filter}
            key={filter}
          />
        ))}
        {totalCount > 1 &&
          <SelectedFilterButton onClick={clearSelected}>
            {I18n.t(webLabels.clearAll)}
          </SelectedFilterButton>
        }
      </div>
    )
  }

  return null
}

function getValidFitersToShow(selectedFilters) {
  const FILTERSMAP = CREATEFILTERSMAP()
  let build = []
  let totalCount = 0

  Object.keys(FILTERSMAP).forEach(filterKey => {
    if (FILTERSMAP[filterKey].webLabel && selectedFilters[filterKey]) {
      build.push(filterKey)
      totalCount += selectedFilters[filterKey].length
    }
  })

  return [build, totalCount]
}
