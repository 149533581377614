import React from 'react'
import classNames from 'classnames'

import { useContent } from 'hooks'
import { getComponentOptions } from 'global-content'
import { LinkSet } from 'components/footer'
import { Heading } from 'components/text'
import { Layout } from 'components/layout'

import styles from './footer-nav.css'

export const FooterNav = ({ mobile, onClick }) => {
  const { content = {} } = useContent('footerNav')
  const { nav } = content
  const { size } = getComponentOptions(`linkSet`)
  const cutOffPoint = Infinity

  if (nav) {
    return (
      <nav className={classNames(styles.footerNav, {
        [styles.columns]: !mobile
      })}>
        {nav.map(renderColumn)}
      </nav>
    )
  }

  return null

  function renderColumn({ items, label }) {
    let navContent = []

    if (mobile || items.length <= cutOffPoint) {
      navContent.push(
        <LinkSet
          items={items}
          key={items[0].label}
          mobile={mobile}
          onClick={onClick}
        />
      )
    } else {
      const halfItems = Math.ceil(items.length / 2)
      const itemsPerColumn = halfItems > cutOffPoint ? halfItems : cutOffPoint
      const columns = Math.ceil(items.length / itemsPerColumn)

      for (let i = 0; i < columns; i++) {
        const itemsToRender = items.slice(i * itemsPerColumn, itemsPerColumn * (i + 1))

        navContent.push(
          <LinkSet
            items={itemsToRender}
            key={itemsToRender[0].label}
            mobile={mobile}
            onClick={onClick}
          />
        )
      }
    }

    return (
      <div
        className={styles.section}
        key={label}
      >
        <Layout
          layout={{
            marginBottom: `xs`
          }}
        >
          <Heading
            content={{
              html: label
            }}
            layout={{
              marginBottom: 'medium'
            }}
            styling={{
              fontSize: size
            }}
          />
        </Layout>
        <div className={styles.navContent}>
          {navContent}
        </div>
      </div>
    )
  }
}
