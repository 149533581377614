import React from 'react'
import { useDispatch } from 'react-redux'

import { updateCartItem } from 'state/actions'
import { I18n } from 'utils/i18n'
import { ItemDetails } from 'components/cart'

import styles from './mini-cart-item.css'

export const webLabels = {
  remove: `WEB.CTA.REMOVE`
}

export const MiniCartItem = ({ item, index }) => {
  const dispatch = useDispatch()

  return (
    <div className={styles.item}>
      <ItemDetails
        data-testid="minicart"
        index={index}
        item={item}
        variation="miniCart"
      >
        <button
          className={styles.remove}
          onClick={() => {
            dispatch(updateCartItem({
              cartItem: item,
              quantity: 0
            }))
          }}
        >
          {I18n.t(webLabels.remove)}
        </button>
      </ItemDetails>
    </div>
  )
}
