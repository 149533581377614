import React, { useState } from 'react'
import classNames from 'classnames'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

import { useUrlManager } from 'hooks'
import { Factory } from 'components/factory'
import { Svg } from 'components/icons'
import styles from './tabs.css'

export const Tabs = ({ config, content, items }) => {
  const { tabNames } = content
  const { tabsParamName } = config
  const normalizeTabNames = tabNames.map(name => name.toLowerCase())

  const urlManager = useUrlManager()
  const { search } = useLocation()
  const parameters = queryString.parse(search)
  const [tabChoice, setTabChoice] = useState(getInitialState())

  return (
    <div className={styles.tabContainer}>
      <div className={styles.desktopWrapper}>
        <div className={styles.buttonWrapper}>
          {tabNames.map((tabName, i) => (
            <button
              className={classNames(styles.button, {
                [styles.active]: i === tabChoice
              })}
              key={tabName}
              onClick={() => handleClick(i)}
            >
              {tabName}
            </button>
          ))}
        </div>
        {getTabContent()}
      </div>

      <div className={styles.mobileWrapper}>
        {tabNames.map((tabName, i) => (
          <div
            className={classNames(styles.tab, {
              [styles.active]: i === tabChoice
            })}
            key={tabName}
          >
            <button
              className={styles.button}
              onClick={() => toggleTab(i)}
            >
              {tabName}
              <Svg icon={i === tabChoice ? `angle_up` : `angle_down`} />
            </button>
            {i === tabChoice &&
              getTabContent()
            }
          </div>
        ))}
      </div>
    </div>
  )

  function getInitialState() {
    if (
      parameters[tabsParamName] &&
      normalizeTabNames.includes(parameters[tabsParamName].toLowerCase())
    ) {
      return normalizeTabNames.findIndex(name => name === parameters[tabsParamName].toLowerCase())
    }

    return 0
  }

  function handleClick(i) {
    urlManager({
      params: {
        append: {
          [tabsParamName]: normalizeTabNames[i]
        }
      }
    })

    setTabChoice(i)
  }

  function toggleTab(choice) {
    if (tabChoice) {
      setTabChoice(null)
    } else {
      setTabChoice(choice)
    }
  }

  function getTabContent() {
    if (tabChoice !== null) {
      return (
        <div className={styles.contentWrapper}>
          <Factory {...items[tabChoice]} />
        </div>
      )
    }

    return null
  }
}
