export function postRedirect({ params, url }) {
  const form = document.createElement('form')
  form.method = 'POST'
  form.action = url
  form.style.display = 'none'

  Object.keys(params).forEach(param => {
    const input = document.createElement('input')
    input.name = param
    input.value = params[param]
    input.readOnly = true
    form.append(input)
  })

  document.body.append(form)
  form.submit()
}
