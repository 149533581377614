import React from 'react'
import { useSelector } from 'react-redux'

import { ProductGrid, ZeroState } from 'components/product-listing'

export const ListingProductGrid = ({ positioning = {} }) => {
  const products = useSelector(state => state.listing.products)
  const loading = useSelector(state => state.listing.loading)
  const {
    displayOptions,
    itemsPerRow,
    itemsToShow
  } = positioning

  const props = {
    displayOptions,
    itemsPerRow,
    itemsToShow
  }

  if (loading) {
    return (
      <ProductGrid
        {...props}
        skeleton
      />
    )
  }

  if (!products.length) {
    return (
      <ZeroState />
    )
  }

  return (
    <ProductGrid
      {...props}
      analyticsTitle="grid"
      logger="PLP-NoResults"
      products={products}
    />
  )
}
