import React from 'react'

import { FormField } from 'components/form'

export const webLabels = {
  label: `WEB.CHECKOUT.SHIPPING.TELEPHONE`
}

export const PhoneField = () => (
  <FormField
    name="consumer.phone"
    type="phoneFormat"
    label={webLabels.label}
    validate={validatePhone}
  />
)

// this relies on validation through react-intl-tel-input setting isValid
function validatePhone(valueObj) {
  if (!valueObj) {
    return 'required'
  }

  const { value, isValid } = valueObj
  if (!isValid) {
    if (value === '') {
      return 'required'
    } else {
      return 'format'
    }
  }
}
