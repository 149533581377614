export const storageFurrealz = {
  get: (key) => {
    // TODO: Remove this in future builds, to remain only JSON.parse
    try {
      return JSON.parse(window.localStorage.getItem(key))
    } catch (e) {
      console.warn(`Failed to parse from local storage: ${key}`)
      console.warn(e)
      return window.localStorage.getItem(key)
    }
  },
  set: (key, value) => {
    return window.localStorage.setItem(key, JSON.stringify(value))
  },
  remove: (key) => {
    return window.localStorage.removeItem(key)
  }
}

const keyForThisBuild = (key) => `${key}_${window.$TIMESTAMP}`

export const storage = {
  get: key => storageFurrealz.get(keyForThisBuild(key)),
  set: (key, value) => storageFurrealz.set(keyForThisBuild(key), value),
  remove: key => storageFurrealz.remove(keyForThisBuild(key))
}

// NOTE: if you use this version of storage, be sure to
// validate and protect against stored data before you use it!
export const unversionedStorage = {
  get: key => storageFurrealz.get(key),
  set: (key, value) => storageFurrealz.set(key, value),
  remove: key => storageFurrealz.remove(key)
}
