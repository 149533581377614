import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import merge from 'lodash/merge'
import isEqual from 'lodash/isEqual'
import { withRouter } from 'react-router-dom'
import Sticky from 'react-stickynode'

import { getComponentOptions, getContent } from 'global-content'
import { capitalize } from 'utils'
import { updateGoogleAnalytics } from 'services/analytics'

import {
  openPullout,
  overlayOff,
  overlayOn
} from 'state/actions'

import {
  BannerNavigation,
  FavoritesLink,
  HeaderBar,
  HeaderSearchInput,
  LanguageSelector,
  MiniCart
} from 'components/header'

import { Svg } from 'components/icons'
import { Megamenu, NavigationMenu } from 'components/navigation'
import { Link } from 'components/text'

import styles from './header.css'

let hoverIntent
let hideDelay = 200 // set to 0 to disable auto-hide
const fingerprintElementId = 'device-fingerprint'

class HeaderClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.initialState()
    this.bannerNavRef = React.createRef()
  }

  initialState() {
    const { breadcrumbs } = this.props

    return {
      relative: this.isBannerNavRelative(),
      activeL1: breadcrumbs.length ? breadcrumbs[0].slug : undefined,
      activeL2: undefined,
      augmentedMegamenu: undefined,
      hoverL1: undefined,
      hoverL2: undefined,
      showBannerNavigation: this.showBannerNavigation(),
      showMegamenu: false
    }
  }

  componentDidUpdate(prevProps) {
    const { augmentedMegamenu } = this.state
    const { activeLanguage, breadcrumbs } = this.props
    const navMenu = getContent(`navMenu`, activeLanguage)
    const navTree = getContent(`navTree`, activeLanguage)

    if (!augmentedMegamenu) {
      this.updateState({
        augmentedMegamenu: merge({}, navTree, get(navMenu, 'megamenu.augmentations', {}))
      })
    }

    if (!isEqual(breadcrumbs, prevProps.breadcrumbs)) {
      this.updateState({
        relative: this.isBannerNavRelative(),
        activeL1: breadcrumbs.length && breadcrumbs[0].slug,
        showBannerNavigation: this.showBannerNavigation()
      })
    }
  }

  componentWillUnmount() {
    this.cancel = true
  }

  isBannerNavRelative() {
    const { activeLanguage, breadcrumbs } = this.props
    const navMenu = getContent(`navMenu`, activeLanguage)
    const persistence = get(navMenu, 'bannerNavigation.persist', false)

    if (!persistence) {
      return false
    }

    if (persistence === 'always') {
      return true
    }

    if (persistence === 'whenActive' && get(breadcrumbs, `[1].slug`)) {
      return true
    }
  }

  showBannerNavigation() {
    const { activeLanguage, breadcrumbs } = this.props
    const navMenu = getContent(`navMenu`, activeLanguage)
    const persistence = get(navMenu, 'bannerNavigation.persist', false)

    if (persistence === 'always') {
      return true
    }

    if (persistence === 'whenActive' && get(breadcrumbs, `[1].slug`)) {
      return true
    }
  }

  openPullout() {
    const { dispatch } = this.props
    dispatch(overlayOn('pullout'))
    dispatch(openPullout('mobileNavigation'))
  }

  /***********************/

  reset() {
    const { dispatch } = this.props

    this.preventMegamenu()
    dispatch(overlayOff('megamenu'))
    this.updateState(this.initialState())
  }

  preventMegamenu() {
    if (this.expandIntent) {
      clearTimeout(this.expandIntent)
    }
  }

  hasNoLevelToShow(level, option) {
    const { activeL1, augmentedMegamenu } = this.state

    if (level === 'l1') {
      return !get(augmentedMegamenu, `l1.${option}`, {}).hasOwnProperty('l2')
    }

    if (level === 'l2' && activeL1) {
      return !get(augmentedMegamenu, `l1.${activeL1}.l2.${option}`, {}).hasOwnProperty('l3')
    }
  }

  onLevelHover(level, option, connection) {
    const { dispatch } = this.props

    if (this.hasNoLevelToShow(level, option)) {
      this.updateState({
        [`active${capitalize(level)}`]: option,
        [`show${capitalize(connection)}`]: false
      })

      dispatch(overlayOff('megamenu'))
      return
    }

    if (level === 'l1') {
      this.updateState({
        activeL2: null,
        hoverL2: option
      })

      if (connection !== 'megamenu') {
        dispatch(overlayOff('megamenu'))
      }
    }

    if (connection !== 'megamenu') {
      this.preventMegamenu()
      this.updateState({
        [`active${capitalize(level)}`]: option,
        [`show${capitalize(connection)}`]: true,
        [`hover${capitalize(level)}`]: option
      })
      return
    }

    this.expandIntent = setTimeout(() => {
      this.updateState({
        [`active${capitalize(level)}`]: option,
        [`hover${capitalize(level)}`]: option
      })

      if (connection) {
        this.updateState({
          [`show${capitalize(connection)}`]: true
        })
        dispatch(overlayOn('megamenu'))
      }
    }, hideDelay)
  }

  onMouseOver() {
    clearTimeout(hoverIntent)
  }

  onMouseOut() {
    this.preventMegamenu()
    if (hideDelay) {
      hoverIntent = setTimeout(() => this.reset(), hideDelay)
    }
  }

  updateState(newState) {
    if (!this.cancel) {
      this.setState(newState)
    }
  }

  /***********************/

  closeMenu() {
    this.preventMegamenu()
    this.reset()
  }

  onContinueToBasket() {
    this.preventMegamenu()
    this.reset()
    const { history } = this.props
    history.push('/checkout/basket')
  }

  renderLogo(device) {
    const logo = getComponentOptions(`logo`)

    return (
      <div
        className={styles.logoContainer}
        key="logo"
      >
        <Link
          className={styles.logo}
          data-testid="header-logo"
          onClick={this.handleLogoClick.bind(this)}
          to="/"
        >
          <Svg
            icon={logo[device] || 'logo'}
            size="100%"
          />
        </Link>
      </div>
    )
  }

  handleLogoClick() {
    this.reset()
    updateGoogleAnalytics(`clickLogo`)
  }

  renderHeaderNavigation() {
    const { activeLanguage } = this.props
    const { activeL1, activeL2, hoverL1, hoverL2 } = this.state
    const navMenu = getContent(`navMenu`, activeLanguage)

    const augmentation = {
      ...navMenu.headerNavigation,
      layout: {
        container: get(navMenu, 'headerNavigation.layout.container', 'clientDefault')
      },
      positioning: {
        itemSpacing: get(navMenu, 'headerNavigation.positioning.itemSpacing', `medium`)
      }
    }

    if (navMenu.headerNavigation) {
      return (
        <NavigationMenu
          activeL1={activeL1}
          activeL2={activeL2}
          augmentation={augmentation}
          hoverL1={hoverL1}
          hoverL2={hoverL2}
          linkType="menuLinkPrimary"
          key="headerNavigation"
          onClick={this.reset.bind(this)}
          onHover={this.onLevelHover.bind(this)}
          type="header"
        />
      )
    }

    return null
  }

  renderMegaMenu() {
    const { activeLanguage } = this.props
    const navMenu = getContent(`navMenu`, activeLanguage)

    const { activeL1, activeL2, showMegamenu, relative } = this.state
    const activationLevel = {
      l2: activeL1,
      l3: activeL2
    }

    if (showMegamenu && activationLevel[navMenu.megamenu.level]) {
      return (
        <Megamenu
          activeL1={activeL1}
          activeL2={activeL2}
          augmentation={navMenu.megamenu}
          key="megamenu"
          offset={get(this.bannerNavRef, 'current.offsetHeight', 0)}
          onClick={this.reset.bind(this)}
          relative={relative}
        />
      )
    }
  }

  renderLanguageSelector() {
    return (
      <LanguageSelector
        key="languageSelector"
        onSelect={this.reset.bind(this)}
      />
    )
  }

  renderSearchBar() {
    return (
      <HeaderSearchInput
        key="searchBar"
        onSubmit={this.closeMenu.bind(this)}
      />
    )
  }

  renderFavoritesButton() {
    return (
      <FavoritesLink key="favoritesButton" />
    )
  }

  renderMenuButton() {
    const { menuIconSize } = getComponentOptions(`header`)

    return (
      <button
        data-testid="menu-button"
        key="menu-button"
        onClick={this.openPullout.bind(this)}
      >
        <Svg
          icon="menu"
          size={menuIconSize}
        />
      </button>
    )
  }

  renderMiniCart() {
    return (
      <MiniCart
        key="minicart"
        onCtaClick={this.onContinueToBasket.bind(this)}
      />
    )
  }

  renderOrder({ device, order }) {
    const componentMap = {
      headerLinks: this.renderHeaderNavigation(),
      favoritesButton: this.renderFavoritesButton(),
      languageSelector: this.renderLanguageSelector(),
      logo: this.renderLogo(device),
      menuButton: this.renderMenuButton(),
      miniCart: this.renderMiniCart(),
      searchBar: this.renderSearchBar()
    }

    return ['left', 'center', 'right'].filter(position => Object.keys(order).indexOf(position) > -1).map(key => (
      <div key={key} className={styles[key]}>
        {order[key].map(component => componentMap[component])}
      </div>
    ))
  }

  render() {
    const { activeL1, activeL2, hoverL1, hoverL2, showBannerNavigation, relative } = this.state

    return (
      <nav
        className={styles.header}
        role="navigation"
        onMouseOver={this.onMouseOver.bind(this)}
        onMouseOut={this.onMouseOut.bind(this)}
      >
        <form className={styles.checkout}>
          <input
            type="hidden"
            name={fingerprintElementId}
            id={fingerprintElementId}
          />
        </form>
        <Sticky top={0}>
          <HeaderBar renderOrder={this.renderOrder.bind(this)} />
          <BannerNavigation
            activeL1={activeL1}
            activeL2={activeL2}
            hoverL1={hoverL1}
            hoverL2={hoverL2}
            onClick={this.reset.bind(this)}
            onHover={this.onLevelHover.bind(this)}
            relative={relative}
            ref={this.bannerNavRef}
            show={showBannerNavigation}
          />
          {this.renderMegaMenu()}
        </Sticky>
      </nav>
    )
  }
}

const mapStateToProps = state => ({
  activeLanguage: state.language.active,
  breadcrumbs: state.navigation.breadcrumbs
})

export const Header = withRouter(connect(mapStateToProps)(HeaderClass))
