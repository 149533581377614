import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'

export function augmentation(originalItems, augmentItems) {
  if (!augmentItems) {
    return originalItems
  }

  const build = cloneDeep(originalItems)

  augmentItems.forEach(augmentItem => {
    const { augmentId, method, ...data } = augmentItem.data

    findId(
      build,
      augmentId,
      method,
      {
        data,
        items: augmentItem.items
      }
    )
  })

  return build
}

function findId(
  target,
  augmentId,
  method,
  augmentItem
) {
  let found = false

  if (Array.isArray(target)) {
    const foundIndex = target.findIndex(item => get(item, `data.augmentId`) === augmentId)

    if (foundIndex > -1) {
      found = true

      target[foundIndex] = addOrReplace({
        augmentItem,
        method,
        target: target[foundIndex]
      })
    } else {
      for (let i = 0; i < target.length; i++) {
        if (found) {
          break
        }

        findId(target[i].items, augmentId, method, augmentItem)
      }
    }
  }
}

function addOrReplace({ augmentItem, method, target }) {
  if (method === `remove`) {
    return {}
  }

  if (method === `replace`) {
    return augmentItem
  }

  return merge({}, target, augmentItem)
}
