
import { UPDATE_NAVIGATION } from 'state/actions'

const initialState = {
  breadcrumbs: [],
  currentPage: {}
}

const navigation = (state = initialState, action) => {
  switch (action.type) {
  case UPDATE_NAVIGATION:
    return action.payload
  default:
    return state
  }
}

export default navigation
