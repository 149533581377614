import React from 'react'
import classNames from 'classnames'

import { ButtonGroup } from 'components/button-group'
import { Image } from 'components/images'
import { Link, Heading, HtmlText, Subtitle } from 'components/text'
import { Layout } from 'components/layout'

import styles from './text-and-image.css'

export const TextAndImage = ({
  config = {},
  content = {},
  'data-testid': testId,
  positioning = {},
  styling = {}
}) => {
  const {
    onClick
  } = config

  const {
    buttons, // []
    description,
    href, // overrides button links
    image = {},
    target,
    subtitle,
    title
  } = content

  const {
    buttonsOrientation, // horizontal | vertical
    captionDirection,
    captionMargin = [], // [x, y]
    captionMarginBottom,
    captionPadding = [],
    captionPosition = [], // [x, y]
    captionWidth = '100%',
    descriptionMarginBottom,
    imageMarginBottom,
    imageWidth,
    matchButtonWidths,
    subtitleBeforeTitle, // boolean
    subtitleMarginBottom,
    textAlign,
    textOverlay,
    titleMarginBottom,
    buttonsPosition = captionPosition // equals the caption position by default
  } = positioning

  const {
    captionBackgroundColor,
    captionColor,
    descriptionFontSize,
    descriptionFontStyle,
    descriptionFontWeight,
    descriptionLineHeight,
    subtitleColor,
    subtitleFontFamily,
    subtitleFontSize,
    subtitleFontStyle,
    subtitleFontWeight,
    subtitleLineHeight,
    titleColor,
    titleFontFamily,
    titleFontSize,
    titleFontStyle,
    titleFontWeight,
    titleLineHeight
  } = styling

  const {
    alt,
    fade,
    src,
    ratio
  } = image

  const [xCaptionPosition, yCaptionPosition] = captionPosition
  const [, yButtonsPosition] = buttonsPosition
  const positionStrategy = getPositionStrategy()

  const positionStrategyClasses = {
    absolute: styles.absolute,
    flex: styles.flex
  }

  const innerProps = {
    className: innerClasses(),
    'data-testid': testId,
    style: {
      position: 'relative'
    }
  }

  // DOM puts the image first
  if (['bottom', 'right'].includes(captionDirection) || textOverlay) {
    return (
      <div {...innerProps} >
        {renderImage()}
        {renderCaption()}
      </div>
    )
  }

  // ['top', 'left'] DOM puts the image second
  return (
    <div {...innerProps}>
      {renderCaption()}
      {renderImage()}
    </div>
  )

  function getPositionStrategy() {
    if (yCaptionPosition === yButtonsPosition) {
      return 'flex'
    }

    return 'absolute'
  }

  function innerClasses() {
    const classesMap = {
      right: styles.captionHorizontal,
      left: styles.captionHorizontal
    }

    return classesMap[captionDirection]
  }

  function renderImage() {
    const imageJsx = (
      <Layout
        className={styles.imageWrapper}
        data-testid="text-and-image-imageWrapper"
        layout={{
          marginBottom: imageMarginBottom
        }}
        style={{
          flexBasis: imageWidth,
          maxWidth: imageWidth,
          width: imageWidth
        }}
      >
        <Image
          prefix
          alt={alt}
          fade={fade}
          ratio={ratio}
          src={src}
          style={{
            width: '100%'
          }}
        />
      </Layout>
    )

    if (href) {
      return (
        <Link
          data-testid="text-and-image-link"
          onClick={onClick}
          target={target}
          to={href}
        >
          {imageJsx}
        </Link>
      )
    }

    return imageJsx
  }

  function renderCaption() {
    return (
      <div
        className={classNames({
          [styles.textOverlay]: textOverlay
        })}
        data-testid="text-and-image-caption"
        style={{
          color: captionColor,
          pointerEvents: href && textOverlay && 'none'
        }}
      >
        <Layout
          className={styles.caption}
          layout={{
            marginBottom: captionMarginBottom,
            marginHorizontal: captionMargin[0],
            marginVertical: captionMargin[1]
          }}
        >
          <div
            className={classNames({
              [styles.textOverlay]: textOverlay,
              [styles.center]: yCaptionPosition === 'center' || yButtonsPosition === 'center'
            })}
          >
            <Layout
              className={inlayClasses()}
              layout={{
                backgroundColor: captionBackgroundColor,
                paddingHorizontal: captionPadding[0],
                paddingVertical: captionPadding[1],
                width: captionWidth
              }}
              style={{
                textAlign
              }}
            >
              {renderTitles()}
              {description &&
                <Layout
                  layout={{
                    marginBottom: descriptionMarginBottom
                  }}
                  style={{
                    fontStyle: descriptionFontStyle,
                    fontWeight: descriptionFontWeight,
                    lineHeight: descriptionLineHeight
                  }}
                >
                  <HtmlText
                    html={description}
                    fontSize={descriptionFontSize}
                  />
                </Layout>
              }
              {positionStrategy === 'flex' && buttons && renderButtons()}
            </Layout>
            {positionStrategy === 'absolute' && buttons && renderButtons()}
          </div>
        </Layout>
      </div>
    )
  }

  function inlayClasses() {
    let classes = []

    if (captionPosition.length) {
      const xStyleMap = {
        left: styles.xLeft,
        right: styles.xRight,
        center: styles.xCenter
      }

      const yStyleMap = {
        top: styles.yTop,
        bottom: styles.yBottom
      }

      classes = [
        ...classes,
        xStyleMap[xCaptionPosition],
        yStyleMap[yCaptionPosition],
        positionStrategyClasses[positionStrategy]
      ]
    }

    return classNames(classes)
  }

  function renderTitles() {
    const titleJsx = (
      <Layout
        layout={{
          marginBottom: titleMarginBottom
        }}
      >
        <Heading
          content={{
            html: title
          }}
          styling={{
            color: titleColor,
            fontFamily: titleFontFamily,
            fontSize: titleFontSize,
            fontStyle: titleFontStyle,
            fontWeight: titleFontWeight,
            lineHeight: titleLineHeight
          }}
        />
      </Layout>
    )

    const subtitleJsx = (
      <Layout
        layout={{
          marginBottom: subtitleMarginBottom
        }}
      >
        <Subtitle
          content={{
            html: subtitle
          }}
          styling={{
            color: subtitleColor,
            fontFamily: subtitleFontFamily,
            fontSize: subtitleFontSize,
            fontStyle: subtitleFontStyle,
            fontWeight: subtitleFontWeight,
            lineHeight: subtitleLineHeight
          }}
        />
      </Layout>
    )

    if (subtitleBeforeTitle) {
      return (
        <React.Fragment>
          {subtitle && subtitleJsx}
          {title && titleJsx}
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        {title && titleJsx}
        {subtitle && subtitleJsx}
      </React.Fragment>
    )
  }

  function renderButtons() {
    return (
      <div
        className={buttonsWrapperClasses()}
        style={{
          textAlign,
          width: positionStrategy !== `flex` && captionWidth
        }}
      >
        <ButtonGroup
          buttons={generateButtons()}
          matchButtonWidths={matchButtonWidths}
          orientation={buttonsOrientation}
        />
      </div>
    )
  }

  function buttonsWrapperClasses() {
    const classes = [styles.buttonsWrapper, positionStrategyClasses[positionStrategy]]

    if (buttonsPosition.length) {
      const [xPosition, yPosition] = buttonsPosition

      const xStyleMap = {
        left: styles.xLeft,
        right: styles.xRight,
        center: styles.xCenter
      }

      const yStyleMap = {
        bottom: styles.yBottom
      }

      classes.push(xStyleMap[xPosition])
      classes.push(yStyleMap[yPosition])
    }

    return classNames(...classes)
  }

  function generateButtons() {
    return buttons.map(button => {
      return {
        config: {
          tag: `span`
        },
        content: getButtonContent(button),
        positioning: {
          buttonHeight: button.height,
          buttonWidth: button.width
        },
        styling: {
          backgroundColor: button.backgroundColor,
          borderRadius: button.borderRadius,
          color: button.color,
          fontSize: button.fontSize,
          textDecoration: button.textDecoration,
          type: button.type
        }
      }
    })
  }

  function getButtonContent(button) {
    if (textOverlay && href) {
      return {
        href,
        html: button.text,
        target
      }
    }

    return {
      href: button.href || href,
      html: button.text,
      target: button.target
    }
  }
}
