import fetch from 'node-fetch'

export default function getConfigData() {
  let target = `/themes/config.json?cb=${window.$TIMESTAMP}`

  return new Promise((resolve, reject) => {
    fetch(target).then((d) => {
      return d.json()
    }).then((json) => {
      resolve(json)
    }).catch(() => {
      reject(`getConfigData: [FAILED TO FETCH] config.json`)
    })
  })
}
