import React from 'react'
import classNames from 'classnames'

import { Button } from 'components/buttons'

import styles from './selectable-box.css'

export const SelectableBox = ({
  'data-testid': testId,
  disabled,
  label,
  nowrap,
  onClick,
  selected,
  size = 'large'
}) => (
  <Button
    className={classNames(styles.button, {
      [styles.small]: size === `small`,
      [styles.large]: size === `large`,
      [styles.disabled]: disabled,
      [styles.selected]: selected
    })}
    data-testid={testId}
    nowrap={nowrap}
    onClick={onClick}
    styling={{
      type: `secondary`
    }}
  >
    {label}
  </Button>
)
