import React from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { closePullout, overlayOff } from 'state/actions'

import styles from './overlay.css'

export const Overlay = () => {
  const dispatch = useDispatch()
  const {
    megamenu,
    mobileSearch,
    pullout,
    tooltip
  } = useSelector(state => state.overlay)

  if (megamenu) {
    return (
      <div className={styles.overlay} />
    )
  }

  if (tooltip) {
    return (
      <div className={styles.tooltip} />
    )
  }

  if (pullout) {
    return (
      <div
        className={classNames(styles.mobile, styles.pullout)}
        onClick={() => closeMobile('pullout')}
      />
    )
  }

  if (mobileSearch) {
    return (
      <div className={classNames(styles.mobile, styles.mobileSearch)} />
    )
  }

  return null

  function closeMobile(overlay) {
    dispatch(overlayOff(overlay))
    dispatch(closePullout())
  }
}
