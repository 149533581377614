import React from 'react'
import ReactResponsive from 'react-responsive'

import mediaQueries from 'css/media-queries.json'
import { capitalize } from 'utils'
import { augmentation } from 'utils/augmentation'

export const MediaQuery2 = ({
  augmentItems,
  children: Children,
  items
}) => {
  const devices = items.map(item => item.data.media)

  return (
    items.map((item, i) => {
      const { media, ...rest } = item.data

      return (
        <ReactResponsive
          key={media}
          query={getMediaQuery(devices, i)}
        >
          <Children
            {...rest}
            items={augmentation(item.items, augmentItems)}
          />
        </ReactResponsive>
      )
    })
  )
}

function getMediaQuery(devices, i) {
  const thisDevice = devices[i]
  const nextDevice = devices[i + 1]
  const minQuery = nextDevice ? `above${capitalize(nextDevice)}` : 'any'

  if (thisDevice === 'desktop') {
    return mediaQueries[minQuery]
  }

  if (!nextDevice) {
    return mediaQueries[thisDevice]
  }

  return `${mediaQueries[thisDevice]} and ${mediaQueries[minQuery]}`
}
