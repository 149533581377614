import {
  FAVORITE_ADD,
  FAVORITE_REMOVE
} from 'state/actions'
import { unversionedStorage } from 'utils/storage'

const validate = (storedData) => {
  if (!Array.isArray(storedData)) {
    return []
  }

  return storedData.filter(favorite => {
    return favorite && favorite.id
  })
}

const initialState = {
  all: validate(unversionedStorage.get('favorites')) || []
}

function saveFavorites(favorites) {
  unversionedStorage.set('favorites', favorites)
}

const favorites = (state = initialState, action) => {
  switch (action.type) {
  case FAVORITE_ADD:
    const toAdd = action.payload
    const combinedFavorites = state.all.concat(toAdd)
    saveFavorites(combinedFavorites)
    return {
      ...state,
      all: combinedFavorites
    }
  case FAVORITE_REMOVE:
    const toRemove = action.payload
    const revisedFavorites = state.all.filter(f => f.id !== toRemove.id)
    saveFavorites(revisedFavorites)
    return {
      ...state,
      all: revisedFavorites
    }
  default:
    return state
  }
}

export default favorites
