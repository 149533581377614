import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { useContent } from 'hooks'
import { getConfigOptions, getMetaOptions } from 'global-content'
import { Scaffold } from 'components/scaffold'
import {
  Brand,
  Brands,
  CustomPage,
  Complete,
  Faceted,
  Favorites,
  Homepage,
  Checkout,
  NotFound,
  Product,
  RecreateCart,
  Redirect,
  SearchResults,
  Tracking
} from 'pages'

export const CHECKOUT_ROUTE = `/checkout/:step?`
export const COMPLETE_ROUTE = `/complete`
export const FAVORITES_ROUTE = `/my-favorites`
export const PRODUCT_ROUTE = `/product/:slug/:id?`
export const REDIRECT_ROUTE = `/redirect`
export const SEARCH_ROUTE = `/search`
export const TRACKING_ROUTE = `/tracking`
export const RECREATE_CART_ROUTE = `/recreateCart`

// Can't export them as static as we have
// to get their configOptions once global content has loaded
export const getBrandRoute = () => `/:l1?/${getConfigOptions('brandsPathname')}/:brand`
export const getBrandsIndex = () => `/:l1?/${getConfigOptions(`brandsPathname`)}`

export const Router = () => {
  const activeLanguage = useSelector(state => state.language.active)
  const navTree = useContent(`navTree`)

  return (
    <BrowserRouter
      key={activeLanguage}
      basename={`/${activeLanguage}-${getMetaOptions('country')}`}
    >
      {/* Scaffold also handles side effects for page switching */}
      <Scaffold>
        <Switch>
          <Route exact path="/" component={Homepage} />
          {renderClpRoutes()}
          <Route exact path={REDIRECT_ROUTE} component={Redirect} />
          <Route exact path={COMPLETE_ROUTE} component={Complete} />
          <Route exact path={TRACKING_ROUTE} component={Tracking} />
          <Route exact path={PRODUCT_ROUTE} component={Product} />
          <Route exact path={CHECKOUT_ROUTE} component={Checkout} />
          <Route exact path={RECREATE_CART_ROUTE} component={RecreateCart} />
          <Route exact path={SEARCH_ROUTE} component={SearchResults} />
          <Route exact path={FAVORITES_ROUTE} component={Favorites} />
          <Route exact path={getBrandRoute()} component={Brand} />
          <Route exact path={getBrandsIndex()} component={Brands} />
          <Route render={navTreeSearch} />
        </Switch>
      </Scaffold>
    </BrowserRouter>
  )

  function renderClpRoutes() {
    return window.$content.routes.list.map(route => (
      <Route
        key={route}
        exact
        path={`/${route}`}
        render={() => (
          <CustomPage
            folder="/data/clp"
            source={`${route.split('/').join('_')}.json`}
            cacheName={`/${route}`}
            // cacheName is so we can easily reference its data
            // in the navigation actions using the clp list
          />
        )}
      />
    ))
  }

  function navTreeSearch({ location }) {
    if (isInNavTree(location.pathname)) {
      return (
        <Faceted />
      )
    }

    return (
      <NotFound
        logger={{
          parent: `Router`,
          pathname: location.pathname
        }}
      />
    )
  }

  function isInNavTree(pathname) {
    let isValid = false
    const levels = pathname.split('/').slice(1)

    walkTree(navTree.l1)

    function walkTree(branch, i = 0) {
      const key = levels[i]

      if (branch && branch[key]) {
        isValid = true
        const l = `l${i + 2}`
        const childBranch = branch[key][l]

        if (levels[i + 1]) {
          walkTree(childBranch, i + 1)
        }
      } else {
        isValid = false
      }
    }

    return isValid
  }
}
