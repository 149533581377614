import { loadValidation } from 'services/api'
import { getMetaOptions } from 'global-content'

export function loadSchema(file, language) {
  return loadValidation(file, language).then(data => {
    if (!window.$content.schema) {
      window.$content.schemas = {}

      getMetaOptions('languages').forEach(lang => {
        window.$content.schemas[lang] = {}
      })
    }

    window.$content.schemas[language][file] = data
  })
}
