import React from 'react'

import { HtmlText, Heading, Link, Subtitle } from 'components/text'

export const Text = ({ config = {}, content, styling = {} }) => {
  const { href, html, target } = content
  const { type, ...restOfStyling } = styling

  if (href) {
    return (
      <Link
        target={target}
        to={href}
      >
        {renderContent()}
      </Link>
    )
  }

  return renderContent()

  function renderContent() {
    const props = {
      config,
      content,
      styling: restOfStyling
    }

    if (type === `heading`) {
      return (
        <Heading {...props} />
      )
    }

    if (type === `subtitle`) {
      return (
        <Subtitle {...props} />
      )
    }

    return (
      <HtmlText
        html={html}
        {...restOfStyling}
      />
    )
  }
}
