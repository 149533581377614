import React from 'react'
import classNames from 'classnames'

import { getComponentOptions } from 'global-content'

import styles from './swatch.css'

export const Swatch = ({
  swatchHex,
  swatchPath,
  swatchSize,
  swatchType = getComponentOptions(`swatch.type`)
}) => {
  return (
    <div
      className={classNames(
        styles.swatch,
        styles[swatchType],
        styles[swatchSize]
      )}
      style={inlineSwatchStyle()}
    />
  )

  function inlineSwatchStyle() {
    if (swatchPath) {
      return {
        backgroundImage: `url(${swatchPath})`
      }
    }

    if (swatchHex && swatchHex[0]) {
      return {
        background: swatchHex.length > 1 ? generateGradient() : `${formatHex(swatchHex[0])}`
      }
    }

    const stripeSize = 2.5
    return {
      background: `repeating-linear-gradient(-45deg, rgba(186, 218, 85, 1), rgba(186, 218, 85, 1) ${stripeSize}px, black ${stripeSize}px, black ${stripeSize * 2}px)`
    }
  }

  function generateGradient() {
    let colors = swatchHex.map(hex => `${formatHex(hex)} ${100 / swatchHex.length}%`).join(',')

    return `linear-gradient(-45deg, ${colors})`
  }

  function formatHex(hex) {
    if (hex.charAt(0) === '#') {
      return hex
    }

    return `#${hex}`
  }
}
