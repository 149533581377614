import React from 'react'
import classNames from 'classnames'

import { Svg } from 'components/icons'

import styles from './nav-arrow.css'

export const NavArrow = ({
  color,
  direction,
  onClick // added by Slick
}) => {
  return (
    <button
      className={classNames(styles.navArrow, {
        [styles.left]: direction === `left`,
        [styles.right]: direction === `right`
      })}
      onClick={onClick}
      style={{ color: color }}
    >
      <Svg
        icon={`angle_${direction}`}
        size="2rem"
      />
    </button>
  )
}
