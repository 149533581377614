import React from 'react'
import classNames from 'classnames'
import merge from 'lodash/merge'
import { Form, reduxForm } from 'redux-form'
import { useSelector } from 'react-redux'

import { updateGoogleAnalytics } from 'services/analytics'
import { Button } from 'components/buttons'
import { DynamicForm } from 'components/form'
import { getMetaOptions } from 'global-content'
import { getSiteName } from 'utils/i18n'
import { Heading } from 'components/text'

import styles from './contact-form.css'

const ContactFormComponent = ({
  apiURL,
  authToken,
  enquire,
  failure,
  fields,
  handleSubmit,
  layout,
  success
}) => {
  const [submitState, setSubmitState] = React.useState()
  const formValues = useSelector(state => state.form.contactUs.values)
  const activeLanguage = useSelector(state => state.language.active)

  if (submitState === 'success' || submitState === 'failure') {
    let headerValue = success.header
    let contentValue = success.content

    if (submitState === 'failure') {
      headerValue = failure.header
      contentValue = failure.content
    }

    return (
      <div
        className={classNames({
          [styles.failure]: submitState === 'failure'
        })}
      >
        <Heading
          content={{
            html: headerValue
          }}
          styling={{
            fontFamily: `primary`,
            fontSize: `large`,
            universalColor: true
          }}
        />
        <p>{contentValue}</p>
      </div>
    )
  }

  return (
    <Form
      className={styles.form}
      data-testid="contact-form"
      onSubmit={handleSubmit(sendEnquiry)}
    >
      <DynamicForm
        fields={fields}
        formValues={formValues}
        layout={layout}
        step="contact"
      />
      <Button
        data-testid="contact-form-button"
        disabled={submitState === 'submitting'}
        positioning={{
          buttonWidth: `large`
        }}
        styling={{
          type: `primary`
        }}
      >
        {enquire}
      </Button>
    </Form>
  )

  function sendEnquiry() {
    setSubmitState('submitting')

    const base64Auth = window.btoa(authToken)
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Basic ${base64Auth}`
      },
      body: getTicketPayload(),
      resolveWithFullResponse: true
    }

    return fetch(apiURL, options)
      .then(response => {
        if (response.status === 201) {
          setSubmitState('success')
          updateGoogleAnalytics(`contactUs`)
        } else {
          console.warn('Error: Freshdesk API, response status: ', response.status)
          setSubmitState('failure')
        }
      })
  }

  function getTicketPayload() {
    const defaultValues = {
      description: '',
      subject: getSiteName(),
      email: '',
      priority: 3,
      status: 2,
      type: '',
      product_id: parseInt(getMetaOptions('integrations.freshdesk.productId'), 10),
      custom_fields: {
        cf_language: activeLanguage
      }
    }

    const mergedFormValues = merge(defaultValues, Object.assign({}, formValues))

    const formData = new FormData()

    for (const key in mergedFormValues) {
      if (key === 'attachment') {
        const attachmentsArray = [...mergedFormValues[key]]
        attachmentsArray.map(value => {
          formData.append('attachments[]', value)
        })
      } else if (key === 'custom_fields') {
        Object.keys(mergedFormValues[key]).map(cf => {
          formData.append(`${key}[${cf}]`, mergedFormValues[key][cf])
        })
      } else {
        formData.append(key, mergedFormValues[key])
      }
    }

    return formData
  }
}

export const ContactForm = reduxForm({
  form: 'contactUs',
  destroyOnUnmount: false,
  initialValues: {
    custom_fields: {}
  }
})(ContactFormComponent)
