import React from 'react'

import { Image } from 'components/images'

import shared from './skeleton.css'

export const SkeletonImage = ({ ratio }) => (
  <div className={shared.skeleton}>
    <Image ratio={ratio} />
  </div>
)
