import React from 'react'
import classNames from 'classnames'

import { Svg } from 'components/icons'

import styles from './animated-icon.css'

export const AnimatedIcon = ({
  animating = false,
  animation,
  icon,
  size
}) => (
  <div
    className={styles.container}
    style={{ width: size }}
  >
    <div className={styles.center}>
      <div
        className={classNames(styles.svgWrapper, {
          [styles[animation]]: animating
        })}
      >
        <Svg
          icon={icon}
          size={size}
        />
      </div>
    </div>
  </div>
)
