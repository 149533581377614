import React from 'react'
import { useSelector } from 'react-redux'

import { I18n } from 'utils/i18n'
import { Button } from 'components/buttons'
import { AnimatedIcon } from 'components/icons'
import { Link } from 'components/text'
import { PageBody } from 'components/scaffold'
import { PageTemplate } from 'pages'
import { Factory } from 'components/factory'

import styles from './favorites.css'

export const webLabels = {
  noResults: `WEB.FAVORITES.NORESULTS`,
  continue: `WEB.FAVORITES.CONTINUE`
}

export const Favorites = () => {
  return (
    <PageTemplate template="plp">
      {FavoritesPageComponent}
    </PageTemplate>
  )
}

const FavoritesPageComponent = ({ items, ...props }) => {
  const favorites = useSelector(state => state.favorites.all)

  if (!favorites.length) {
    return (
      <PageBody>
        <div className={styles.empty}>
          <div className={styles.iconWrapper}>
            <AnimatedIcon
              animating
              animation="pulse"
              icon="heart-o"
              size="8rem"
            />
          </div>
          <div className={styles.description}>
            {I18n.t(webLabels.noResults)}
          </div>
          <Link to="/">
            <Button
              config={{
                tag: `span`
              }}
              positioning={{
                buttonHeight: `medium`,
                buttonWidth: `large`
              }}
              styling={{
                singleLine: true,
                type: `primary`
              }}
            >
              {I18n.t(webLabels.continue)}
            </Button>
          </Link>
        </div>
      </PageBody>
    )
  }

  return (
    <PageBody {...props}>
      <Factory items={items} />
    </PageBody>
  )
}
