import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'

import { Layout } from 'components/layout'
import { ControlTrack } from 'components/carousel'
import { NavArrow } from './nav-arrow'

import styles from './carousel.css'

export const Carousel = ({
  config = {},
  items,
  positioning = {}
}) => {
  const [activeItem, setActiveItem] = React.useState(0)
  const sliderRef = React.useRef()
  const components = useSelector(state => state.components)

  React.useEffect(() => {
    setActiveItem(0)
  }, [JSON.stringify(items)])

  const {
    borderFix,
    autoplaySpeed,
    centerMode = false,
    fade = false,
    infinite = false,
    slidesToShow = 1,
    slidesToScroll = 1
  } = config

  const {
    controlsArrows,
    controlsDots,
    controlsPosition,
    controlsRatio,
    controlsSize,
    controlsType,
    gap
  } = positioning

  let order = [renderSlider()]

  if (controlsType === `thumbs`) {
    if (controlsPosition === `left`) {
      order.unshift(renderControlTrack())
    } else {
      order.push(renderControlTrack())
    }
  }

  return order.map(item => item)

  function renderSlider() {
    return (
      <Slider
        arrows={controlsArrows}
        autoplay={Boolean(autoplaySpeed)}
        autoplaySpeed={autoplaySpeed}
        afterChange={current => {
          setActiveItem(current)
        }}
        centerMode={centerMode}
        className={classNames(styles.slickSlider, {
          [styles.borderFix]: borderFix
        })}
        dots={controlsDots}
        fade={fade}
        infinite={infinite}
        key="slider"
        nextArrow={<NavArrow direction="right" />}
        prevArrow={<NavArrow direction="left" />}
        ref={slider => { sliderRef.current = slider }}
        slidesToScroll={slidesToScroll}
        slidesToShow={slidesToShow}
        speed={300}
      >
        {
          items.map((item, i) => {
            const { component, layout, ...itemProps } = item.data
            const Component = components[component] || (() => <div />)

            return (
              <div key={item.id}>
                <div style={{ margin: `0 ${halveGap()}` }}>
                  <Layout layout={layout}>
                    <Component
                      {...itemProps}
                      items={item.items}
                      config={{
                        ...(item.data.config || {}),
                        active: activeItem === i
                      }}
                    />
                  </Layout>
                </div>
              </div>
            )
          })
        }
      </Slider>
    )
  }

  function renderControlTrack() {
    return (
      <ControlTrack
        activeItem={activeItem}
        controlsPosition={controlsPosition}
        controlsRatio={controlsRatio}
        controlsSize={controlsSize}
        items={items}
        key="controlTrack"
        onClick={e => sliderRef.current.slickGoTo(e)}
      />
    )
  }

  // assumes the number is first
  function halveGap() {
    if (gap) {
      const value = parseInt(gap)
      const halved = value / 2
      const unit = gap.replace(String(value), '')

      return `${halved}${unit}`
    }

    return 0
  }
}
