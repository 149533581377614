import React from 'react'
import ReactDOM from 'react-dom'
import 'svgxuse'

import store from 'state/reducers'
import { loadedDependencies } from 'state/actions'

import CSSLoader from 'utils/cssLoader'
import Logger from './utils/logger'
import Login from './utils/login'
import ShoppingSessionId from './utils/ShoppingSessionId'
import Maintenance from './utils/maintenance'

import { mailChimp } from 'utils/mailChimp'
import { forter } from 'utils/forter'
import { analytics, favicon, fonts, getLanguage, tracking } from 'utils'
import { loadFonts } from 'utils/loadFonts'
import { sentrySetup } from './utils/sentry'
import { I18n } from 'utils/i18n'
import getMetaData from './utils/getMetaData'
import getConfigData from './utils/getConfigData'
import { siteLoader } from './utils/siteLoader'
import { naverPixel } from './utils/naverPixel'

import {
  setGlobalConfig,
  getMetaOptions
} from 'global-content'

import { contentDependencies } from 'global-content/content'
import { setAlgoliaIndices } from 'global-content/algolia'
import { setComponents } from 'global-content/components'
import { setShippingOptions } from 'global-content/shippingOptions'
import { setThemes } from 'global-content/themes'
import { setPrefixedUrl } from 'utils/setPrefixedUrl'

import { App } from 'app'
import { AppError } from 'components/errors'

import 'css/reset.css'

// Render loader
siteLoader()

Promise.all([
  getMetaData(),
  getConfigData()
]).then(([meta, config]) => {
  window.$HISTORY = []
  window.$META = meta
  window.$ENVIRONMENT = getMetaOptions('environment')
  window.$CLIENT = getMetaOptions('clientTag')
  window.$COUNTRY = getMetaOptions('country')
  window.$HASH = getMetaOptions('logins')
  window.$maintenance = new Maintenance()
  window.$logger = new Logger()
  window.$login = new Login()
  window.$login.init()
  window.$shoppingSessionId = new ShoppingSessionId()
  window.$shoppingSessionId.init()
  window.$content = {}

  setGlobalConfig(config)
  setPrefixedUrl()
  setAlgoliaIndices()
  setShippingOptions() // TODO: move to a separate JSON file / api call
  setComponents() // TODO: move to a separate JSON file / api call
  setThemes() // TODO: move to a separate JSON file / api call

  sentrySetup()
  loadFonts()
  naverPixel()
  fonts()

  // Dependencies before paint
  I18n.get().then(() => {
    window.$maintenance.init()
    const language = getLanguage()

    return Promise.all([
      CSSLoader.init(),
      contentDependencies(language)
    ])
  }).then(() => {
    store.dispatch(loadedDependencies())
    window.ready = true

    // Dependencies after paint
    analytics()
    favicon()
    mailChimp()
    forter()
  }).catch(err => {
    window.error = err.message
  })
})

window.bootstrap = setInterval(() => {
  if (window.supported && window.auth && window.ready) {
    tracking()
    clearInterval(window.bootstrap)
    ReactDOM.render(<App />, document.getElementById('root'))
  }

  if (window.error) {
    clearInterval(window.bootstrap)
    ReactDOM.render(<AppError />, document.getElementById('root'))
  }
}, 60)

if (module.hot) {
  module.hot.accept()
}
