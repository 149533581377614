import get from 'lodash/get'

import { loadContent } from 'services/content'
import { translate } from 'utils/translate'

export function contentDependencies(language) {
  return Promise.all([
    loadClpRoutes(language),
    loadSeoData(language),
    loadBadges(language),
    loadPromotions(language),
    loadFooter(language),
    loadFooterNav(language),
    loadHead(language),
    loadNavigation(language)
  ])
}

export function getContent(key, language) {
  const content = get(window.$content, key)

  if (content) {
    return content[language] || content
  }
}

export function setContent(target, content, language) {
  window.$content[target] = {
    ...window.$content?.[target],
    [language]: translate(content, language)
  }
}

export function loadBadges(language) {
  return loadContent('/data/badges.json').then(data => {
    setContent(`badges`, data.content, language)
  })
}

export function loadFooter(language) {
  return loadContent('/data/footer.json').then(data => {
    setContent(`footer`, data.content, language)
  })
}

export function loadFooterNav(language) {
  return loadContent('/data/footerNav.json').then(data => {
    setContent(`footerNav`, data.content, language)
  })
}

export function loadHead(language) {
  return loadContent('/data/head.json').then(data => {
    setContent(`head`, data.content, language)
  })
}

export function loadNavigation(language) {
  return Promise.all([
    loadContent('/data/navTree.json'),
    loadContent('/data/navMenu.json')
  ]).then(([navTree, navMenu]) => {
    setContent(`navTree`, navTree.content, language)
    setContent(`navMenu`, navMenu.content, language)
  })
}

export function loadPromotions(language) {
  return loadContent('/data/promotions.json').then(data => {
    setContent(`promotions`, data.content, language)
  })
}

export function loadClpRoutes() {
  // returns list of CLP routes from a json file generated during build process
  // in lithos-content. these routes are then injected into our router
  return loadContent('/data/clp-routes.json').then(data => {
    window.$content.routes = {
      list: data.content
    }
  })
}

export function loadSeoData(language) {
  return loadContent('/data/seoData.json').then(data => {
    setContent(`seoData`, data.content, language)
  })
}
