import React from 'react'
import classNames from 'classnames'
import { animateScroll } from 'react-scroll'

import { getComponentOptions } from 'global-content'

import { Svg } from 'components/icons'

import styles from './scroll-up.css'

export class ScrollUp extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.checkScrollPosition.bind(this))
  }

  componentWillUnmount() {
    this.cancel = true
    window.removeEventListener('scroll', this.checkScrollPosition.bind(this))
  }

  scrollToTop() {
    animateScroll.scrollToTop()
  }

  checkScrollPosition() {
    const { scrollY, innerHeight } = window
    const { visible } = this.state
    const toShow = scrollY > innerHeight && !visible
    const toHide = scrollY < innerHeight && visible

    if (!this.cancel) {
      if (toShow) {
        this.setState({
          visible: true
        })
      } else if (toHide) {
        this.setState({
          visible: false
        })
      }
    }
  }

  render() {
    const { size } = getComponentOptions(`scrollUp`)
    const { visible } = this.state

    return (
      <button
        onClick={this.scrollToTop}
        className={classNames(styles.container, {
          [styles.visible]: visible
        })}
      >
        <Svg
          icon="arrow"
          size={size}
        />
      </button>
    )
  }
}
