import React from 'react'

import { Layout } from 'components/layout'
import { Heading } from 'components/text'

export const CategoryHeading = ({ html }) => (
  <Layout
    layout={{
      marginBottom: `xxs`
    }}
  >
    <Heading
      content={{
        html
      }}
      styling={{
        fontFamily: 'secondary',
        fontSize: 'xs',
        universalColor: true
      }}
    />
  </Layout>
)
