import React from 'react'

import { getComponentOptions } from 'global-content'
import { FilterMenuButton, OptionsContainer } from 'components/filters'

import styles from './filter-bellow.css'

export const FilterBellow = ({
  buttonClass,
  filter,
  filtersPadding,
  optionsClass,
  usePending
}) => {
  const [active, setActive] = React.useState(filter.open)
  const { name } = filter
  const { close, fontSize, open } = getComponentOptions(`filterMenuButton.content.areas.accordion`)

  return (
    <div className={styles.filterBellow}>
      <FilterMenuButton
        active={active}
        className={buttonClass}
        closeIcon={close.iconRef}
        fontSize={fontSize}
        name={name}
        onClick={toggle}
        openIcon={open.iconRef}
        padding={filtersPadding}
      >
        {active &&
          <div className={optionsClass}>
            <OptionsContainer
              filter={filter}
              filtersPadding={filtersPadding}
              usePending={usePending}
            />
          </div>
        }
      </FilterMenuButton>
    </div>
  )

  function toggle() {
    setActive(!active)
  }
}
