import mergeWith from 'lodash/mergeWith'
import get from 'lodash/get'

import { customizer } from 'utils/mergeCustomizer'

export function setGlobalConfig(config) {
  window.$CONFIG = mergeWith({}, config, customizer)
}

export function getConfigOptions(key) {
  return get(window.$CONFIG, key)
}

export function getMetaOptions(key) {
  // todo: find a better way to add hooks
  if (key === 'currencyCountry') {
    return `${getMetaOptions('currency.code')}-${getMetaOptions('country').toUpperCase()}`
  }
  return get(window.$META, key)
}
