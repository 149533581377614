import React from 'react'
import classNames from 'classnames'
import Parser from 'html-react-parser'
import domToReact from 'html-react-parser/lib/dom-to-react'

import { Link } from 'components/text'
import withErrorBoundry from 'components/errors/with-error-boundry'

import styles from './html-text.css'

export const HtmlText = withErrorBoundry(({
  color,
  elements,
  fontSize,
  html,
  itemProp,
  textAlign
}) => {
  return (
    <div
      className={classNames(styles.htmlText, {
        [styles.large]: fontSize === `large`,
        [styles.regular]: fontSize === `regular`,
        [styles.small]: fontSize === `small`,
        [styles.xs]: fontSize === `xs`
      })}
      itemProp={itemProp}
      style={{
        color,
        textAlign
      }}
    >
      {parse(html, elements)}
    </div>
  )
}, `text`, `HtmlText-Parsing-Error`)

function parse(html, elements) {
  try {
    return Parser(html, {
      replace: node => replace(node, elements)
    })
  } catch (e) {
    throw `Can't parse ${JSON.stringify(html)}`
  }
}

function replace(node, childrenStyling) {
  const { attribs, children, name } = node
  let style

  if (childrenStyling && childrenStyling[name]) {
    style = childrenStyling[name]
  }

  if (style) {
    const Tag = name

    return (
      <Tag
        {...attribs}
        style={style}
      >
        {domToReact(children)}
      </Tag>
    )
  }

  if (name === 'a') {
    return (
      <Link
        target={attribs.target}
        to={attribs.href}
        style={style}
      >
        {domToReact(children)}
      </Link>
    )
  }
}
