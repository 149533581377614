import React from 'react'

import { useUrlManager } from 'hooks'
import { getMetaOptions } from 'global-content'
import { setClientCookie } from 'utils'
import { updateGoogleAnalytics } from 'services/analytics'
import { PageBody } from 'components/scaffold'

export const Redirect = () => {
  const urlManager = useUrlManager()

  React.useEffect(() => {
    const { key, value, domain, to } = getMetaOptions('cookieRedirect') || {}

    if (key && value) {
      setClientCookie(key, value, domain)
    }

    updateGoogleAnalytics(`backToUsSite`, {
      url: window.$HISTORY[window.$HISTORY.length - 1]
    })

    if (window.$HISTORY.length) {
      document.title = `Redirecting to ${to}`
      window.location.href = to
    } else {
      urlManager({
        method: `replace`,
        pathname: {
          value: `/`
        }
      })
    }
  }, [])

  return (
    <PageBody loading />
  )
}
