import React from 'react'
import classNames from 'classnames'

import { getComponentOptions } from 'global-content'

import { LinkText } from 'components/text'
import { Layout } from 'components/layout'

import styles from './side-nav-link.css'

const SideNavLink = ({
  active,
  button,
  children,
  onClick,
  to,
  value
}) => {
  const {
    config: { activeState, hoverState },
    layout = {}
  } = getComponentOptions(`sideNavLink`)

  return (
    <Layout
      className={classNames(
        styles.container,
        {
          [styles[`hoverState${hoverState}`]]: hoverState
        })}
      layout={{
        marginBottom: layout.marginBottom
      }}
    >
      <LinkText
        active={active}
        activeState={activeState}
        button={button}
        hoverState={hoverState}
        onClick={onClick}
        textAlign="left"
        to={to}
        value={value}
      >
        {value}
      </LinkText>
      {children}
    </Layout>
  )
}

export { SideNavLink }
