import {
  OPEN_PULLOUT,
  CLOSE_PULLOUT
} from 'state/actions'

const initialState = {
  content: undefined,
  active: false
}

const pullout = (state = initialState, action) => {
  switch (action.type) {
  case OPEN_PULLOUT:
    return {
      ...state,
      content: action.payload,
      active: true
    }
  case CLOSE_PULLOUT:
    return {
      ...state,
      content: undefined,
      active: false
    }
  default:
    return state
  }
}

export default pullout
