import React from 'react'
import onClickOutside from 'react-onclickoutside'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { Svg } from 'components/icons'
import { Heading, HtmlText } from 'components/text'

import { overlayOn, overlayOff, setActiveTooltip } from 'state/actions'

import styles from './tooltip.css'

// This is a class component due to react-onclickoutside
// works more nicely with classes, rather than functional components
class TooltipComponent extends React.Component {
  toggleTooltip() {
    const { activeTooltip, dispatch, name } = this.props
    if (activeTooltip !== name) {
      dispatch(overlayOn('tooltip'))
      dispatch(setActiveTooltip(name))
    }
  }

  closeTooltip() {
    const { dispatch } = this.props
    dispatch(overlayOff('tooltip'))
    dispatch(setActiveTooltip(''))
  }

  handleClickOutside(e) {
    const { activeTooltip, name } = this.props

    if (activeTooltip === name) {
      this.closeTooltip()
    }
  }

  render() {
    const {
      activeTooltip,
      image,
      name = '',
      positioning = ['top', 'left'],
      title,
      value
    } = this.props

    const positioningStyles = positioning.map(p => styles[p])
    const active = activeTooltip === name

    return (
      <div className={styles.container}>
        <button
          onClick={this.toggleTooltip.bind(this)}
          type="button"
          data-testid={`tooltip-toggle-${name}`}
        >
          <Svg
            icon="info_sign"
            size="1.4rem"
          />
        </button>
        {active &&
          <div
            className={classNames(styles.tooltip, styles.override, ...positioningStyles)}
            data-testid='tooltip'
          >
            <button
              className={styles.close}
              onClick={this.closeTooltip.bind(this)}
              type="button"
              data-testid={`tooltip-close-${name}`}
            >
              <Svg icon="close" />
            </button>
            {title &&
              <Heading
                content={{
                  html: title
                }}
                styling={{
                  fontFamily: `primary`,
                  fontSize: `xs`,
                  universalColor: true
                }}
              />
            }
            {image &&
              <img src={image} />
            }
            {value &&
              <div data-testid="tooltip-value">
                <HtmlText html={value} />
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  activeTooltip: state.site.activeTooltip
})

export const Tooltip = connect(mapStateToProps)(onClickOutside(TooltipComponent))
