import fetch from 'node-fetch'

export default function GetMetaData() {
  let target = null
  let isLocalEnv = window.location.hostname.includes('localhost')
  let isTestEnv = !isLocalEnv && window.location.hostname.includes('localised-') // the isLocalEnv is actually redundant since isTestEnv occurs in an else statement, also the '-' is important!
  let isCmsApi = window.location.hostname.includes('cms-api')

  if (isLocalEnv || isCmsApi) {
    target = window.$LOCALMETA ? `/local/metadata.${window.$PORTCLIENTMAP[window.location.port]}.json` : `/metadata.json`
  } else {
    const testHost = `r03-fe-metadata.s3.amazonaws.com`
    const prodHost = `r03prod01-c01-fe-metadata.s3.amazonaws.com`
    const host = isTestEnv ? testHost : prodHost
    target = `https://${host}/${window.location.hostname}-current/metadata.json?cb=${window.$TIMESTAMP}`
  }

  return new Promise((resolve, reject) => {
    fetch(target).then((d) => {
      return d.json()
    }).then((json) => {
      resolve(json)
    }).catch(() => {
      reject(`GetMetaData: [FAILED TO FETCH] ${target}`)
    })
  })
}
