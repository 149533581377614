import * as Sentry from '@sentry/browser'
import store from 'state/reducers'
import { getMetaOptions } from 'global-content/config'

export const I18n = {
  languageMap: {},
  replaceMap: {},

  get() {
    return Promise.all(getMetaOptions('languages').map(language => {
      return fetch(`/language/${language}.json?cb=${window.$TIMESTAMP}`)
        .then(response => {
          return response.json()
        })
        .then(json => {
          this.languageMap[language] = json
          this.replaceMap[language] = {
            cartName: getCartName(language),
            clientName: getClientName(),
            country: getCountryName(language),
            freeShipThreshold: getMetaOptions(`freeShipThreshold`),
            localisedAddress: getMetaOptions(`localisedAddress`),
            localisedCountry: getMetaOptions(`localisedCountry`),
            localisedEntity: getMetaOptions(`localisedEntity`),
            returnAddress: getMetaOptions(`returnAddress`),
            returnShippingPrice: getMetaOptions(`returnShippingPrice`),
            returnsPeriod: getMetaOptions(`returnsPeriod`),
            shoppingSessionId: window.$shoppingSessionId.value,
            siteName: getSiteName(),
            name: getSiteName(),
            timeToShip: getMetaOptions(`timeToShip`)
          }
        })
    }))
  },

  t(i18nKey, options = {}) {
    const {
      language = store.getState().language.active
    } = options

    if (!this.languageMap[language]) {
      return i18nKey
    }

    const value = this.languageMap[language][i18nKey]

    if (value === undefined) {
      return i18nKey
    }

    return this.interpolate(value, options)
  },

  interpolate(value, options = {}) {
    if (value) {
      const {
        language = store.getState().language.active,
        logMissing = true,
        replace = {}
      } = options

      const regEx = /{{(.+?)}}/gi
      const interpolated = value.replace(regEx, (match, p1) => {
        if (replace.hasOwnProperty(p1) || this.replaceMap[language]?.hasOwnProperty(p1)) {
          return replace[p1] || this.replaceMap[language][p1] || ``
        } else {
          if (logMissing) {
            Sentry.captureMessage(`Missing interpolation key ${match} for ${value}`)
          }

          return match
        }
      })

      return interpolated.replace(/\\n/g, '\n') // removing extra escape characters in front of line breaks in fetched text
    }

    return ``
  }
}

// TODO: re-locate these to the proper place. we had circular references
//       between this and global-content. in general, the utils folder
//       needs a re-evaluation
export function getCartName(language) {
  return I18n.t(`WEB.CART.NAME.${window.$CONFIG.cartName.toUpperCase()}`, {
    language
  })
}

export function getCountry() {
  return window.$META.country.toUpperCase()
}

export function getCountryName(language) {
  // note: country name is only specify in english and local language
  return I18n.t(`COUNTRY.${getCountry()}`, {
    language
  })
}

export function getClientName() {
  // always in english
  return window.$META.name
}

export function getSiteName() {
  // returns the language specific name in LOKA if available, otherwise concats
  // the client name in English with the country name in local language
  const siteName = I18n.t('SITE.NAME')
  if (siteName !== 'SITE.NAME' && siteName !== '') {
    return siteName
  } else {
    return [window.$META.name, getCountryName()].join(' ')
  }
}
