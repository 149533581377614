import React from 'react'
import classNames from 'classnames'

import { FormField } from 'components/form'

import styles from './dynamic-form.css'

export const DynamicForm = ({
  layout,
  fields,
  form,
  formValues,
  step
}) => (
  <React.Fragment>
    {layout.input[step].map((row, i) => (
      <div
        className={classNames({
          [styles.grid]: row.length > 1,
          [`${styles.grid}${row.length}`]: row.length > 1
        })}
        key={i} // eslint-disable-line react/no-array-index-key
      >
        {row.map(name => {
          const stepName = fields[name].name.replace('{{step}}', step)

          return (
            <FormField
              {...fields[name]}
              form={form}
              formValues={formValues}
              key={name}
              name={stepName}
            />
          )
        })}
      </div>
    ))}
  </React.Fragment>
)
