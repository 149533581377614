import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { favoriteAdd, favoriteRemove } from 'state/actions'

import { updateGoogleAnalytics } from 'services/analytics'

import { AnimatedIcon, Svg } from 'components/icons'

import styles from './favorite-button.css'

class FavoriteButtonClass extends React.Component {
  state = {
    adding: false
  }

  exists() {
    const { favorites, variant } = this.props
    const result = favorites.find(f => f.id === variant.id)

    return result
  }

  handleClick() {
    const { dispatch, variant } = this.props
    if (this.exists()) {
      updateGoogleAnalytics('removeFromFavorites', { target: variant })
      dispatch(favoriteRemove(variant))
    } else {
      updateGoogleAnalytics('addToFavorites', { target: variant })
      dispatch(favoriteAdd(variant))
      this.setState({
        adding: true
      })

      setTimeout(() => {
        this.setState({
          adding: false
        })
      }, 500)
    }
  }

  renderHeart() {
    if (this.exists()) {
      return 'heart'
    }

    return 'heart-o'
  }

  renderIcon() {
    const { removeIcon, size } = this.props

    if (removeIcon) {
      return (
        <Svg
          icon="close"
          size={size}
        />
      )
    }

    return (
      <AnimatedIcon
        animating={this.state.adding}
        animation="grow"
        icon={this.renderHeart()}
        size={size}
      />
    )
  }

  render() {
    const style = this.props.style || 'default'

    return (
      <button
        className={classNames(styles.button, styles[style])}
        onClick={() => this.handleClick()}
      >
        {this.renderIcon()}
      </button>
    )
  }
}

const mapStateToProps = state => ({
  favorites: state.favorites.all
})

export const FavoriteButton = connect(mapStateToProps)(FavoriteButtonClass)
