import React from 'react'
import classNames from 'classnames'

import { LinkText } from 'components/text'

import styles from './product-tray-support-link.css'

export const ProductTraySupportLink = ({
  href,
  position,
  size,
  text,
  type
}) => (
  <div
    className={classNames(styles.supportLink, {
      [styles.left]: position === `left`,
      [styles.right]: position === `right`
    })}
    data-testid="merch-component-support-link"
  >
    <LinkText
      to={href}
      size={size}
      type={type || 'textLink'}
    >
      {text}
    </LinkText>
  </div>
)
