import algoliasearch from 'algoliasearch'
import { getConfigOptions, getMetaOptions } from 'global-content/config'

export function setAlgoliaIndices() {
  const integrations = getMetaOptions('integrations')
  const clientTag = getMetaOptions('clientTag')
  const { account, catalog, key, filters } = integrations.algolia
  const client = algoliasearch(account, key, { protocol: 'https:' })

  window.$content.algolia = assembleIndices()

  function assembleIndices() {
    const indices = {}
    indices.filtersIndex = client.initIndex(filters)

    getConfigOptions(`sortOptions`).forEach(({ index, value }) => {
      indices[value] = client.initIndex(`${catalog}-${clientTag.toUpperCase()}${index ? '-' : ''}${index}`)
    })

    return indices
  }
}

export function getAlgoliaIndex(selectedSort) {
  return window.$content.algolia[selectedSort]
}
