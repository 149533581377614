import _filter from 'lodash/filter'
import { getConfigOptions, getMetaOptions } from 'global-content'

import { logToCloudWatch } from 'utils/logToCloudWatch'

//  type SimpleFilter = Array<string>

//  type DetailedFilter = {
//    includes: SimpleFilter;
//    excludes: SimpleFilter;
//  }

//  interface GetProducts {
//    analytics?: String;
//    index: AlgoliaIndex;
//    logger?: String;
//    options: {
//      facets?: Array<FacetKey>;
//      filters: {
//        brand?: SimpleFilter | DetailedFilter;
//        categories?: SimpleFilter | DetailedFilter;
//        collections?: SimpleFilter | DetailedFilter;
//        color?: SimpleFilter | DetailedFilter;
//        priceRange?: [min, max?];
//        size?: SimpleFilter | DetailedFilter;
//        slug?: SimpleFilter | DetailedFilter;
//        onSale?: Boolean;
//      }
//    };
//    hitsPerPage?: Number;
//    includeOutOfStock?: Boolean;
//    page?: Number;
//    query?: String;
//    selectedSort?: string;
//  }

export const getProducts = ({
  analytics,
  format = true,
  index,
  language,
  logger,
  options,
  selectedSort
}) => {
  // prioritizedFilters are the filters we use when we have a featured sort which prepends its results to the listing items
  // so we make two calls to algolia and stitch the results together
  const optionalFilters = getPrioritizedFilters(selectedSort)

  // normalize the filters to ensure they are the same shape. We have an implicit 'includes' to make writing basic filters simpler
  // { categories: [`womens`] } normalized becomes
  // { categories: { includes: [`womens`] } }
  const normalizedFilters = normalizeFiltersObject(options.filters)
  const facets = getFacets(options.facets)

  return fetchProducts({
    analytics,
    facets,
    index,
    logger,
    options,
    normalizedFilters,
    optionalFilters,
    selectedSort
  })
    .then(response => formatResults({ analytics, format, language, optionalFilters, response }))
    .catch(error => handleError({ error, options }))
}

/* implementation functions */

export function getPrioritizedFilters(selectedSort) {
  const sortOptions = getConfigOptions(`sortOptions`)
  const selectedSortOptions = sortOptions.find(option => option.value === selectedSort)

  return (
    selectedSortOptions &&
    selectedSortOptions.prioritizedResults &&
    normalizeFiltersObject(selectedSortOptions.prioritizedResults)
  )
}

export function fetchProducts({
  facets,
  index,
  options = {},
  normalizedFilters,
  optionalFilters
}) {
  const {
    attributesToRetrieve = `*`,
    hitsPerPage,
    page,
    query
  } = options

  const { includeOutOfStock } = options.filters || {}
  const { facetFilters, filters, numericFilters } = formatFiltersForAlgolia(normalizedFilters, includeOutOfStock)

  return index.search({
    attributesToRetrieve,
    facetFilters,
    facetingAfterDistinct: false,
    facets,
    filters,
    hitsPerPage,
    numericFilters,
    optionalFilters: optionalFilters && formatFiltersForAlgolia(optionalFilters, includeOutOfStock).facetFilters,
    page,
    query
  })
}

export function formatResults({
  format,
  optionalFilters,
  response
}) {
  if (!format) {
    return response
  }

  if (optionalFilters) {
    const featureSortCollection = optionalFilters.collections.includes[0]

    response.hits.sort((a, b) => {
      const aSort = a.collections && a.collections.find(collection => collection.tag === featureSortCollection)
      const bSort = b.collections && b.collections.find(collection => collection.tag === featureSortCollection)

      if (aSort && bSort) {
        return aSort.rank - bSort.rank
      }

      if (!aSort) {
        return 1
      }

      if (!bSort) {
        return -1
      }
    })
  }

  return {
    ...response,
    hits: response.hits
  }
}

function handleError({ error, options }) {
  logToCloudWatch('Error-Algolia', { error, options })
  throw new Error(error.message)
}

function getFacets(facets) {
  if (facets) {
    return facets
  }

  const FILTERSMAP = CREATEFILTERSMAP()

  return [
    ..._filter(FILTERSMAP, { filterType: 'facet' }),
    ..._filter(FILTERSMAP, { filterType: 'numeric' })
  ].map(filter => filter.nameInAlgolia)
}

export function formatFiltersForAlgolia(filters, includeOutOfStock) {
  const FILTERSMAP = CREATEFILTERSMAP()
  let unfacetedFilters = ``
  let facetFilters = []
  let numericFilters = [`price.${getMetaOptions('currencyCountry')}.sale > 0`]

  // 1. Map through filters and work out their type using FILTERSMAP
  // 3. For each filter, depending on type, run through unfaceted, faceted or numeric function
  // 4. Assume every filter has an includes / excludes property to know how to filter

  const filterMethods = {
    boolean: booleanFilter,
    facet: facetedFilter,
    numeric: numericFilter,
    unfaceted: unfacetedFilter,
    ignore: ignore
  }

  const filtersArray = Object.keys(filters)

  filtersArray.forEach(filter => {
    if (FILTERSMAP[filter]) {
      const filterType = FILTERSMAP[filter].filterType
      const nameInAlgolia = FILTERSMAP[filter].nameInAlgolia
      filterMethods[filterType](nameInAlgolia, filters[filter])
    }
  })

  if (!includeOutOfStock) {
    unfacetedFilters += `${unfacetedFilters ? ' AND ' : ''}availabilityFlag < 3`
  }

  return {
    facetFilters,
    filters: unfacetedFilters,
    numericFilters
  }

  function facetedFilter(nameInAlgolia, filterOptions = {}) {
    const { includes = [], excludes = [] } = filterOptions

    let array = []
    includes.forEach(filter => {
      array.push(`${nameInAlgolia}:${filter}`)
    })

    excludes.forEach(filter => {
      facetFilters.push(`${nameInAlgolia}:-${filter}`)
    })

    if (array.length) {
      facetFilters.push(array)
    }
  }

  function numericFilter(nameInAlgolia, filterOptions) {
  // Assume numeric data is provided as an array = [min, max]
  // If only max, must provide null or 0 as first paramater
    const min = filterOptions[0]
    const max = filterOptions[1]

    if (min) {
      numericFilters.push(`${nameInAlgolia}>=${min}`)
    }

    if (max) {
      numericFilters.push(`${nameInAlgolia}<=${max}`)
    }
  }

  function unfacetedFilter(nameInAlgolia, filterOptions) {
    const { includes = [], excludes = [] } = filterOptions

    if (includes.length) {
      unfacetedFilters += `${unfacetedFilters ? ' AND ' : ''}(${includes.reduce((acc, val) => acc.concat(`${nameInAlgolia}:${decodeURIComponent(val)}`), []).join(' OR ')})`
    }

    if (excludes.length) {
      unfacetedFilters += `${unfacetedFilters ? ' AND ' : ''}(${excludes.reduce((acc, val) => acc.concat(`NOT ${nameInAlgolia}:${decodeURIComponent(val)}`), []).join(' AND ')})`
    }
  }

  function booleanFilter(nameInAlgolia, filterOptions) {
    unfacetedFilters += `${unfacetedFilters ? ' AND' : ''} ${nameInAlgolia} = ${filterOptions ? '1' : '0'}`
  }

  function ignore() {}
}

export function normalizeFiltersObject(filters) {
  let returnObj = {}

  if (filters) {
    const FILTERSMAP = CREATEFILTERSMAP()
    const filterKeys = Object.keys(filters)
    // check for includes array
    filterKeys.forEach(key => {
      const keyCheck = filters[key]

      if (
        typeof keyCheck === 'object' &&
        !keyCheck.hasOwnProperty('includes') &&
        !keyCheck.hasOwnProperty('excludes') &&
        FILTERSMAP[key] &&
        FILTERSMAP[key].filterType !== 'numeric' &&
        FILTERSMAP[key].filterType !== 'boolean'
      ) {
        returnObj[key] = {
          includes: keyCheck
        }
      } else {
        returnObj[key] = keyCheck
      }
    })
  }

  return returnObj
}

export function refitKeys(input, replaceMap) {
  let build = {}
  const existingKeys = Object.keys(replaceMap)
  const replaceKeys = Object.values(replaceMap)

  for (let key in input) {
    // Get the destination key
    let ix = existingKeys.indexOf(key)
    let destKey = ix === -1 ? key : replaceKeys[ix]

    // Get the value
    let value = input[key]

    // If this is an object, recurse
    if (typeof value === 'object' && !Array.isArray(value)) {
      value = refitKeys(value, replaceMap)
    }

    // Set it on the result using the destination key
    build[destKey] = value
  }

  return build
}

export async function getFilters(language) {
  let result = await getAlgoliaFilters(0, language)
  let filters = result.hits
  let promises = []
  for (let page = 1; page < result.nbPages; page++) {
    promises.push(getAlgoliaFilters(page, language).then(r => r.hits))
  }
  filters = await Promise.all(promises).then(data => data.reduce((a, b) => a.concat(b), filters))

  return filters
}

function getAlgoliaFilters(page = 0, language) {
  const hitsPerPage = 1000

  return window.$content.algolia.filtersIndex.search({
    hitsPerPage: hitsPerPage,
    page: page,
    facetFilters: [
      `siteTag:${getMetaOptions('siteTag')}`,
      `language:${language}`
    ]
  }).then(result => result)
}

// filterType: 'boolean' === unfacetedFilter
export function CREATEFILTERSMAP() {
  return {
    brand: {
      filterType: 'facet',
      nameInAlgolia: 'brand.tag',
      webLabel: 'WEB.LISTING.FILTER.BRAND.TITLE'
    },
    categories: {
      filterType: 'facet',
      nameInAlgolia: 'categories.tag' // There is a facet which is categories.name
    },
    collections: {
      filterType: 'facet',
      nameInAlgolia: 'collections.tag'
    },
    color: {
      categoryLabel: 'colorGroup',
      filterType: 'facet',
      nameInAlgolia: 'color.tag',
      webLabel: 'WEB.LISTING.FILTER.COLOR.TITLE'
    },
    priceRange: {
      currency: true,
      filterType: 'numeric',
      nameInAlgolia: `price.${getMetaOptions(`currencyCountry`)}.sale`,
      step: getMetaOptions('currencyStep'),
      webLabel: 'WEB.LISTING.FILTER.PRICE.TITLE'
    },
    size: {
      filterType: 'facet',
      nameInAlgolia: 'size.tag',
      webLabel: 'WEB.LISTING.FILTER.SIZE.TITLE'
    },
    slug: {
      filterType: 'unfaceted',
      nameInAlgolia: 'slug'
    },
    productId: {
      filterType: 'unfaceted',
      nameInAlgolia: 'productId'
    },
    onSale: {
      filterType: 'boolean',
      nameInAlgolia: 'onSale'
    },
    includeOutOfStock: {
      filterType: 'ignore'
    }
  }
}
