import React from 'react'

import { PageBody } from 'components/scaffold'

const LazyWrapper = ({ children }) => (
  <React.Suspense fallback={
    <PageBody loading />
  }>
    {children}
  </React.Suspense>
)

export { LazyWrapper }
