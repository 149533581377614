import React from 'react'
import { useLocation } from 'react-router-dom'
import get from 'lodash/get'

import { useContent } from 'hooks'
import { Factory, v1FactoryToV2 } from 'components/factory'

export const Head = () => {
  const { pathname } = useLocation()
  const { content } = useContent('head')

  if (!content) {
    return null
  }

  const { routes } = content
  const route = routes[pathname] || routes.all

  if (
    !route.content.components ||
    get(route, 'config.hide', []).includes(pathname)
  ) {
    return null
  }

  const order = route.content.components.order
  const components = route.content.components.items

  return (
    <Factory items={v1FactoryToV2({ order, components })} />
  )
}
