// Important to note we use Google Tag Manager and NOT analytics.js [ga()] or gtag.js
// https://developers.google.com/tag-manager/devguide
import { getMetaOptions } from 'global-content'

export default function analytics() {
  const { id } = getMetaOptions('integrations.googleAnalytics')

  if ((window.$ENVIRONMENT === 'production' || window.$ENVIRONMENT === 'staging') && !window.localStorage.getItem('disableTracking')) {
    /* eslint-disable */
    (function(w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({ 'gtm.start':
    new Date().getTime(),
      event: 'gtm.js' }); let f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
    'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', id)

    let noscript = document.createElement('noscript')
    window.document.body.insertAdjacentHTML('afterbegin', `
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
      </noscript>
    `)
    /* eslint-enable */
  }
}
