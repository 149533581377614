class AfterPay {
  constructor(baseUrl) {
    this.BACKEND_URL_CREATE_TRANSACTION = `${baseUrl}checkout/createTransaction`
  }

  afterPayFlow(cart) {
    let token
    return this.popupDetection().then(() => {
      return this.isValidCountry()
    }).then(() => {
      return this.buildAfterPay()
    }).then(() => {
      return this.createTransaction(cart)
    }).then((d) => {
      token = d && d.token ? d.token : ''
      return this.paymentFlow(token)
    }).catch((e) => {
      console.log(e)
    })
  }

  popupDetection() {
    return new Promise((resolve, reject) => {
      let isFirefox = navigator.userAgent.includes('Firefox')
      if (isFirefox) {
        let popup = window.open('https://pay.google.com')
        if (!popup || popup.closed || typeof popup.closed === 'undefined') {
          reject(`Please enable popups for this website to continue with GooglePay.`)
        } else {
          popup.close()
          resolve()
        }
      } else {
        resolve()
      }
    })
  }

  isValidCountry() {
    return new Promise((resolve, reject) => {
      let countries = ['US', 'CA', 'GB', 'AU', 'NZ']
      let countryCode = window.$COUNTRY.toUpperCase()
      if (!countries.includes(countryCode)) {
        reject('Not a valid country.')
      } else {
        resolve()
      }
    })
  }

  buildAfterPay() {
    if (document.getElementById('afterPayScript')) {
      return Promise.resolve()
    } else {
      return new Promise((resolve, reject) => {
        let script = document.createElement('script')
        script.src = `https://portal.sandbox.afterpay.com/afterpay-async.js`
        script.id = 'afterPayScript'
        script.type = 'text/javascript'
        script.onload = () => {
          resolve()
        }
        let scripts = document.getElementById('scripts')
        scripts.appendChild(script)
      })
    }
  }

  createTransaction(cart) {
    let is200
    return new Promise((resolve, reject) => {
      return fetch(`${this.BACKEND_URL_CREATE_TRANSACTION}`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          orderId: cart.id,
          orderReference: cart.reference
        })
      }).then((d) => {
        is200 = d.ok
        return d.json()
      }).then((d) => {
        if (is200 && d.id) {
          resolve(d)
        } else {
          reject(d)
        }
      }).catch((e) => {
        reject(e)
      })
    })
  }

  paymentFlow(token) {
    window.AfterPay.initialize({ countryCode: window.$COUNTRY.toUpperCase() })
    window.AfterPay.redirect({ token: token })
  }
}

export default AfterPay
