import React from 'react'
import classNames from 'classnames'

import { CheckIcon } from 'components/icons'

import styles from './branded-checkbox.css'

export const BrandedCheckbox = ({
  center = true,
  children,
  color = 'black',
  error,
  input,
  roundedCorners
}) => (
  <label className={classNames(styles.container, styles[color], {
    [styles.center]: center,
    [styles.error]: error,
    [styles.roundedCorners]: roundedCorners
  })}>
    {input}
    <CheckIcon
      color={color}
      roundedCorners={roundedCorners}
    />
    {Boolean(children) &&
      <span>
        {children}
      </span>
    }
  </label>
)
