import findKey from 'lodash/findKey'
import get from 'lodash/get'

import { rangeFromNumber } from 'utils'
import { CREATEFILTERSMAP } from 'services/algolia'

export function toResultsSummary(results) {
  const {
    exhaustiveNbHits,
    facets_stats, // eslint-disable-line camelcase
    hitsPerPage,
    nbHits,
    nbPages,
    page
  } = results

  return {
    approximateTotal: !exhaustiveNbHits,
    currentPage: page,
    numericFilterStats: facets_stats,
    pageList: rangeFromNumber(nbPages),
    pages: nbPages,
    hitsPerPage,
    nbHits
  }
}

export function toPageFacets(facets) {
  const FILTERSMAP = CREATEFILTERSMAP()
  let build = {}

  Object.keys(facets).forEach(facetKey => {
    const niceName = findKey(FILTERSMAP, [`nameInAlgolia`, facetKey])
    build[niceName] = facets[facetKey]
  })

  return build
}

export function toPageFilters(facets, allFilters) {
  const FILTERSMAP = CREATEFILTERSMAP()
  let build = {}

  Object.keys(facets).forEach(facetKey => {
    const niceName = findKey(FILTERSMAP, [`nameInAlgolia`, facetKey])
    const { filterType } = FILTERSMAP[niceName]

    build[niceName] = (
      Object.keys(facets[facetKey])
        .map(option => buildOption(option, niceName, filterType, allFilters))
        .filter(Boolean)
        .sort((a, b) => sortOptions(a, b, niceName))
    )
  })

  return build
}

function buildOption(option, niceName, filterType, allFilters) {
  if (filterType === `numeric`) {
    return {
      value: option
    }
  }

  return get(allFilters, `${niceName}.${option}`)
}

function sortOptions(optionA, optionB, niceName) {
  if (niceName === `brand`) {
    if (optionA.tag < optionB.tag) return -1
    if (optionA.tag > optionB.tag) return 1
    return 0
  }

  return optionA.index - optionB.index
}
