import React from 'react'
import { useSelector } from 'react-redux'

import { getConfigOptions, getMetaOptions } from 'global-content'
import { I18n } from 'utils/i18n'
import { useChangeLanguage } from 'hooks'
import { Svg } from 'components/icons'

import styles from './language-toggle.css'

export const webLabels = {
  select: `WEB.LANGUAGE.SELECT`
}

export const LanguageToggle = ({ iconWidth }) => {
  const switchLanguage = useChangeLanguage()
  const activeLanguage = useSelector(state => state.language.active)

  const useBrowserLanguage = getConfigOptions(`useBrowserLanguage`)
  const languages = getMetaOptions('languages')
  const otherLanguage = languages.filter(language => language !== activeLanguage).join()

  if (languages.length > 1 && useBrowserLanguage) {
    return (
      <button
        className={styles.container}
        data-testid="language-toggle"
        onClick={handleClick}
      >
        <div
          className={styles.iconWrapper}
          style={{ width: iconWidth }}
        >
          <Svg
            icon="globe"
            size="2.5rem"
          />
        </div>
        {I18n.t(webLabels.select, {
          language: otherLanguage
        })}
      </button>
    )
  }

  return null

  function handleClick() {
    switchLanguage(otherLanguage)
  }
}
