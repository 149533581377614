import { getMetaOptions } from 'global-content'

export function mailChimp() {
  if (getMetaOptions('integrations.mailchimp')) {
    const { clientId, siteId } = getMetaOptions('integrations.mailchimp')
    const script = document.createElement('script')
    script.src = `https://chimpstatic.com/mcjs-connected/js/users/${clientId}/${siteId}.js`
    script.id = 'mcjs'
    script.type = 'text/javascript'
    let scripts = document.getElementById('scripts')
    scripts.appendChild(script)
  }
}
