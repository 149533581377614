import React from 'react'

import { Layout } from 'components/layout'
import { HtmlText, Link } from 'components/text'

import styles from './promo-strip.css'

export const PromoStrip = ({
  content = {},
  styling = {}
}) => {
  const { href, html } = content
  const { backgroundColor } = styling

  if (href) {
    return (
      <Link
        className={styles.promoStrip}
        style={{ backgroundColor }}
        to={href}
      >
        {renderContent()}
      </Link>
    )
  }

  return (
    <div
      className={styles.promoStrip}
      style={{ backgroundColor }}
    >
      {renderContent()}
    </div>
  )

  function renderContent() {
    return (
      <Layout layout={{ container: `clientDefault` }}>
        <HtmlText html={html} />
      </Layout>
    )
  }
}
