import React from 'react'
import classNames from 'classnames'

import CSSLoader from 'utils/cssLoader.js'

import { getConfigOptions } from 'global-content'
import { FavoriteButton } from 'components/buttons'
import { Badge } from 'components/badges'
import { CardImage } from 'components/images'
import { Price } from 'components/price'
import { Promotions } from 'components/promotions'
import { Swatches } from 'components/swatches'
import { Heading, Link } from 'components/text'
import { Layout } from 'components/layout'

import styles from './product-card.css'

export const ProductCard = ({
  favIconSize,
  favoritePage,
  handleClick,
  onColorChange,
  position,
  product,
  rankingSize,
  selectedColor,
  selectedImage,
  showBrand,
  showFavoriteButton,
  showLowStock,
  showPrice,
  showRanking,
  showSwatches,
  showTitle,
  swatchLimit,
  swatchSize,
  textAlign,
  url
}) => {
  const {
    availability,
    brand,
    options,
    name,
    price,
    priceRange,
    promotions = {}
  } = product

  const promotionBadge = 'productCard/badge'
  const promotionBanner = 'productCard/banner'
  const promotionAdditional = 'productCard/additional'
  const altTagContent = `${brand.name} ${name}`
  const testId = `product-card-${position}`

  return (
    <div
      data-testid={testId}
      style={{ textAlign: textAlign }}
    >
      <div className={styles.cardImageWrapper}>
        {promotions[promotionBadge] &&
          <div className={styles.badgeWrapper}>
            <Promotions
              area={promotionBadge}
              promotions={promotions}
            />
          </div>
        }
        <CardImage
          applyMargin={showBrand || showPrice || showSwatches || showTitle}
          data-testid={testId}
          imageSource={selectedImage}
          link={url}
          onClick={handleClick}
          position={position}
          title={altTagContent}
        />

        {showRanking &&
          <div className={classNames(styles.ranking, styles[rankingSize])}>{position}</div>
        }

        {showFavoriteButton &&
          <div className={styles.favoriteWrapper}>
            <FavoriteButton
              removeIcon={favoritePage}
              size={favIconSize}
              variant={product}
            />
          </div>
        }

        {promotions[promotionBanner] &&
          <div className={styles.bannerWrapper}>
            <Promotions
              area={promotionBanner}
              promotions={promotions}
            />
          </div>
        }
      </div>

      <div>
        {brand.name && showBrand && getConfigOptions(`variant.show.brand`) &&
          <Layout
            layout={{
              marginBottom: !showTitle && `xxxxs`
            }}
          >
            <Heading
              content={{
                html: brand.name
              }}
              styling={{
                fontWeight: CSSLoader.styles.productCardBrandWeight,
                fontSize: 'xs',
                universalColor: true
              }}
            />
          </Layout>
        }
        {showTitle &&
          <Link
            className={styles.title}
            onClick={handleClick}
            title={name}
            to={url}
          >
            <div
              className={styles.ellipsisWrapper}
              data-testid="product-card-title"
            >
              {name}
            </div>
          </Link>
        }

        {showPrice &&
          <div className={styles.priceWrapper}>
            <Price
              price={price}
              priceRange={priceRange}
              showRange
              type="small"
            />
          </div>
        }

        {showSwatches &&
          <Swatches
            options={options}
            limit={swatchLimit}
            onSelect={onColorChange}
            selectedColor={selectedColor}
            swatchSize={swatchSize}
          />
        }
        {promotions[promotionAdditional] &&
          <div className={styles.textWrapper}>
            <Promotions
              area={promotionAdditional}
              promotions={promotions}
            />
          </div>
        }
        {availability === 'LOWSTOCK' && showLowStock &&
          <Badge type="lowStock" />
        }
      </div>
    </div>
  )
}
