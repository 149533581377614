// id="siteLoader" used in utils/metrics.js

export function siteLoader() {
  const size = 100
  const halfSize = size / 2
  const quarterSize = size / 4

  document.getElementById('root').insertAdjacentHTML('afterbegin', `
  <div style="display: flex; align-items: center; height: 100vh;width: 100vw;">
    <div
      class="css3Loader"
      id="siteLoader"
    >
      <svg width=${size} height=${size}>
        <circle cx=${halfSize} cy=${halfSize} r=${quarterSize} fill="none" strokeWidth="5" />
      </svg>
    </div>
  </div>
  `)
}
