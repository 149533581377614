// hook version of <DataFetching /> HOC
import React from 'react'
import { useSelector } from 'react-redux'
import { logToCloudWatch } from 'utils/logToCloudWatch'
import { translate } from 'utils/translate'

window.$cache = {}

// TODO: cache the translationed versions so translate only runs once?
export function useDataFetching({
  folder,
  source,
  cacheName = `${folder}/${source}`
}) {
  const activeLanguage = useSelector(state => state.language.active)
  const path = `${folder}/${source}`
  const cacheRef = `${cacheName}-${activeLanguage}`
  const [{ file, triedFetch }, setState] = React.useState(initialState(path))

  React.useEffect(() => {
    if (folder && source) {
      if (!window.$cache[cacheRef]) {
        setState({
          file: undefined,
          triedFetch: false
        })
      }

      tryFetch()
    }
  }, [folder, source, activeLanguage])

  return [file, triedFetch]

  function initialState() {
    if (window.$cache[cacheRef]) {
      return window.$cache[cacheRef]
    }

    return {
      file: undefined,
      triedFetch: undefined
    }
  }

  function tryFetch() {
    if (window.$cache.hasOwnProperty(cacheRef)) {
      setState(window.$cache[cacheRef])
      return
    }

    let fetchedFile

    fetch(`${path}?cb=${window.$TIMESTAMP}`)
      .then(response => {
        if (response.ok) {
          const contentType = response.headers.get('content-type')

          if (contentType.startsWith('application/json')) {
            return response.json()
          }

          if (contentType.startsWith('text/markdown')) {
            return response.text()
          }

          return undefined
        }

        throw response
      })
      .then(parsedFile => {
        if (parsedFile) {
          fetchedFile = translate(parsedFile, activeLanguage)
        }
      })
      .catch(() => { logFetchFail() })
      .finally(() => {
        window.$cache[cacheRef] = {
          file: fetchedFile,
          triedFetch: true
        }

        setState({
          file: fetchedFile,
          triedFetch: true
        })
      })
  }

  function logFetchFail() {
    logToCloudWatch('Error-AssetFetch', {
      asset: path
    })
  }
}
