import React from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { SHOW_TEST_PAYMENTS_PARAM } from 'utils/constants'
import { useRouteChange, useScrollToTop } from 'hooks'
import { enableTestPayments, loadCartContents } from 'state/actions'
import { ScrollUp } from 'components/buttons'
import { Footer } from 'components/footer'
import { Pullout } from 'components/pullout'
import { Overlay, SplashModal } from 'components/modal'
import { CookieMessage, HeaderOrder } from 'components/scaffold'

import styles from './scaffold.css'

const fingerprintElementId = 'device-fingerprint'

export const Scaffold = ({ children }) => {
  const location = useLocation()
  const active = useSelector(state => state.pullout.active)
  const history = useHistory()
  const dispatch = useDispatch()
  const updateLocation = useRouteChange()
  useScrollToTop()

  React.useEffect(() => {
    dispatch(loadCartContents())
    const listen = historyListener()
    const parsedSearch = queryString.parse(location.search)

    // calling hasOwnProperty from the Object prototype as
    // queryString.parse uses Object.create(null) (no inherited methods)
    if (Object.prototype.hasOwnProperty.call(parsedSearch, SHOW_TEST_PAYMENTS_PARAM)) {
      dispatch(enableTestPayments())
    }

    return listen
  }, [])

  return (
    <div className={classNames(styles.pageContainer, {
      [styles.pulloutOpen]: active
    })}>
      <Overlay />
      <Pullout /> {/* Should be done using portals? */}
      <HeaderOrder />
      {children}
      <Footer />
      <ScrollUp />
      <CookieMessage />
      <SplashModal />
      <input
        type="hidden"
        name={fingerprintElementId}
        id={fingerprintElementId}
      />
    </div>
  )

  function historyListener() {
    return history.listen(({ pathname, search }, action) => {
      // the timeout is to try and prevent the fall through to not found for a breif second
      // whilst it is fetching data

      setTimeout(() => {
        updateLocation({ pathname, search, action })
      }, 300)
    })
  }
}
