import React from 'react'

import { getComponentOptions } from 'global-content'

import styles from './icon-label.css'

export const IconLabel = ({ iconLabel }) => {
  const { showIconLabels } = getComponentOptions(`header.config`)

  if (showIconLabels) {
    return (
      <div className={styles.iconLabel}>
        {iconLabel}
      </div>
    )
  }

  return null
}
