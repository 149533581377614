import React from 'react'
import classNames from 'classnames'

import { I18n } from 'utils/i18n'
import { currencyFormatted } from 'utils'
import { getComponentOptions, getMetaOptions } from 'global-content'
import { HtmlText } from 'components/text'

import styles from './price.css'

export const webLabels = {
  from: `WEB.PRODUCT.PRICERANGE`,
  pricingStrategy: 'WEB.PRICINGSTRATEGY',
  percentageOff: `WEB.PRODUCT.DISCOUNT`
}

export const Price = ({
  price,
  priceRange = {},
  showRange,
  showPricingStrategy = true,
  type = 'large'
}) => {
  const {
    list = {},
    sale = {}
  } = price
  const { saleMin, saleMax, listMin, listMax } = priceRange
  const percentageOff = discount()
  const onSale = saleMin !== listMin
  const hasRange = saleMin !== saleMax

  const pricingStrategy = I18n.t(webLabels.pricingStrategy)

  return (
    <div className={classNames(styles.container, {
      [styles.large]: type === `large`,
      [styles.regular]: type === `regular`,
      [styles.small]: type === `small`,
      [styles.xs]: type === `xs`
    })}>
      {renderPrice()}
      {showPricingStrategy && getMetaOptions('showPricingStrategy') &&
        <div className={styles.tax}>
          {pricingStrategy}
        </div>
      }
    </div>
  )

  function discount() {
    if (list.total && sale.total) {
      return Math.round((list.total - sale.total) / list.total * 100)
    }

    if (listMin === listMax && saleMin === saleMax) {
      return Math.round((listMin - saleMin) / listMin * 100)
    }
  }

  function renderPrice() {
    if (showRange && hasRange) {
      const { showFrom } = getComponentOptions(`price`)

      if (showFrom && hasRange) {
        return renderFrom()
      }

      return (
        <span className={styles.price}>
          {rangeOrSingle(sale.total, [saleMin, saleMax])}
        </span>
      )
    }

    return (
      <React.Fragment>
        {renderListPrice()}
        {renderDiscountedPrice()}
      </React.Fragment>
    )
  }

  function renderFrom() {
    return (
      <div
        className={styles.fromPrice}
        data-testid="from-text"
      >
        <HtmlText
          html={I18n.t(webLabels.from, {
            replace: {
              listPrice: onSale
                ? `<span class=${styles.originalPrice} data-testid="from-list-min">${currencyFormatted(listMin)}</span>`
                : ``,
              salePrice: `<span class=${onSale && styles.currentPrice} data-testid="from-sale-min">${currencyFormatted(saleMin)}</span>`
            }
          })}
        />
      </div>
    )
  }

  function rangeOrSingle(single, [min, max]) {
    if (min === max || !showRange) {
      return currencyFormatted(single || min)
    }

    return (
      `${currencyFormatted(min)} - ${currencyFormatted(max)}`
    )
  }

  function renderListPrice() {
    return (
      <span
        className={classNames(styles.price, styles[type], {
          [styles.originalPrice]: percentageOff > 0
        })}
        data-testid="originalPrice"
      >
        {rangeOrSingle(list.total, [listMin, listMax])}
      </span>
    )
  }

  function renderDiscountedPrice() {
    if (
      (listMin && listMin === saleMin && listMax === saleMax) ||
      (list.total && list.total === sale.total)
    ) {
      return null
    }

    return (
      <React.Fragment>
        <span
          className={styles.currentPrice}
          data-testid="currentPrice"
        >
          {rangeOrSingle(sale.total, [saleMin, saleMax])}
        </span>
        {percentageOff &&
          <span
            className={classNames(styles.percentageOff, styles[type])}
            data-testid="percetangeOff"
          >
            {I18n.t(webLabels.percentageOff, {
              replace: {
                discount: percentageOff
              }
            })}
          </span>
        }
      </React.Fragment>
    )
  }
}
