import React from 'react'
import ReactResponsive from 'react-responsive'
import cloneDeep from 'lodash/cloneDeep'
import mergeWith from 'lodash/mergeWith'

import mediaQueries from 'css/media-queries.json'
import { capitalize } from 'utils'
import { customizer } from 'utils/mergeCustomizer'
import { MediaQuery2 } from 'components/scaffold'

export const MediaQuery = ({
  augmentItems,
  children,
  ...props
}) => {
  if (props.startingProps && props.startingProps.items) {
    return (
      <MediaQuery2
        augmentItems={augmentItems}
        {...props.startingProps}
      >
        {children}
      </MediaQuery2>
    )
  }

  return (
    <MediaQueryV1 {...props}>
      {children}
    </MediaQueryV1>
  )
}

const MediaQueryV1 = ({
  allDevices,
  children: Children,
  startingProps,
  customProps
}) => {
  // Create the correct tree of props
  const mergedStartingProps = mergeWith(
    checkForDesktop(startingProps),
    checkForDesktop(customProps),
    customizer
  )

  // Add desktop object if missing
  const supportedDevices = Object.keys(mergedStartingProps)
  const devicesArray = supportedDevices.map((device, i) => ({
    device,
    mediaQuery: getQuery(supportedDevices, i),
    ...cloneDeep(mergedStartingProps[device])
  }))

  const finalObj = jsCascade(devicesArray)

  return (
    finalObj.map(deviceProps => {
      const { device, mediaQuery, ...rest } = deviceProps

      return (
        <ReactResponsive
          key={device}
          query={mediaQuery}
        >
          <Children
            {...rest}
            {...allDevices}
            device={device}
          />
        </ReactResponsive>
      )
    })
  )
}

function getQuery(devices, index) {
  const thisDevice = devices[index]
  const nextDevice = devices[index + 1]
  const minQuery = nextDevice ? `above${capitalize(nextDevice)}` : 'any'

  if (thisDevice === 'desktop') {
    return mediaQueries[minQuery]
  }

  if (!nextDevice) {
    return mediaQueries[thisDevice]
  }

  return `${mediaQueries[thisDevice]} and ${mediaQueries[minQuery]}`
}

// Recursive merging
function cascade(array, collectedArray) {
  const deep = collectedArray.length

  if (collectedArray.length < array.length) {
    collectedArray.push(mergeWith([], collectedArray[deep - 1], array[deep], customizer))
    cascade(array, collectedArray)
  }

  return collectedArray
}

// Start the cascade and return the completed array
function jsCascade(array) {
  const collectedArray = [array[0]]
  return cascade(array, collectedArray)
}

function checkForDesktop(props) {
  if (props && props.desktop) {
    return props
  }

  return ({
    desktop: {
      ...cloneDeep(props)
    }
  })
}
