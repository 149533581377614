import { constructUrl, getPromotions } from 'models/utils'
import { getConfigOptions, getMetaOptions } from 'global-content'
import { AVAILABILITY_FLAGS, SALE_STATES, ORDERABLE_STATES } from 'utils/constants'

/*
  Glossary via Examples
  =====================

  Product
    Cashmere Crew Sweater
  Option
    Color
    Size
  Option Values
    Color: White, Gray, Black
    Size: Small, Medium, Large
  Variant:
    Small White Cashmere Crew Sweater
  SKU:
    2564-9456  (this is the identifier for the variant, and the terms are
                some what used interchangeably)

  There are 3 flavors of product data:
    - Product API for Product Details Page (PDP)
    - Algolia for Product Listing Page (PLP)
    - Cart services
  While the data is the same, they are formatted and optimized for their
  corresponding use case.

  We try to unify commmon parts of these 3 product variations in order to
  maintain the same format/structure so that components can be re-used.
  *HOWEVER*, there are no validations in place to enforce that they are
  truly the same.
 */

// https://bitbucket.org/localised-eng/api-specs/src/master/schemas/product.json
export function toDetailProduct(product) {
  const { categories, priceMin, priceMax, price, ...rest } = product
  const filtered = formatDetailOptions(product.options)

  return {
    ...rest,
    // as long as there are some filtered options left return those
    // otherwise use the default unfiltered options
    options: filtered.length ? filtered : product.options,
    ...formatPrices(product),
    categories: sortCategories(categories),
    priceBreakdown: getPriceBreakdown(product),
    promotions: getPromotions(product),
    sizeAndFit: getSizeAndFit(product)
  }
}

// https://bitbucket.org/localised-eng/api-specs/src/master/schemas/sku.json
export function toListingProduct(data, list) {
  const {
    availabilityFlag,
    brand,
    categories,
    collections,
    color,
    name,
    onSale,
    optionLevels,
    options,
    price,
    priceRange,
    productId,
    size,
    sku,
    slug
  } = data

  const currencyCountry = getMetaOptions(`currencyCountry`)
  const mainImage = getMainImage(data)
  // Needs to do a first pass to sanitize the data so it is in the right shape
  // for further sanitizing helper functions, e.g. getPromotions()
  const sanitized = {
    // same as product
    availability: AVAILABILITY_FLAGS[availabilityFlag],
    brand,
    collections,
    id: encodeURIComponent(productId),
    name,
    list,
    priceRange: priceRange[currencyCountry],
    slug,
    // same field name, need to confirm value structure
    categories: categories && sortCategories(categories.map(category => category.tag)),
    optionLevels,
    // unique to listing product
    availabilityFlag,
    color,
    options: formatOptions(options),
    mainImage: mainImage,
    price: {
      sale: {
        total: price[currencyCountry].sale
      },
      list: {
        total: price[currencyCountry].list
      }
    },
    onSale,
    sale: SALE_STATES[onSale],
    size,
    sku,
    url: constructUrl({ productId, slug })
  }

  return {
    ...sanitized,
    promotions: getPromotions(sanitized)
  }
}

function getMainImage(data) {
  const { optionLevels, options } = data
  const firstLevel = optionLevels[0]

  if (data[firstLevel]) {
    const option = options.find(x => x.slug === data[firstLevel].slug)

    if (option) {
      return option.mainImage
    }
  }

  return '/images/no-image/no-image-available.jpg'
}

function formatOptions(options) {
  const withMedia = options.map(option => ({
    ...option,
    media: {
      large: [option.mainImage],
      video: []
    }
  }))

  return filterOOS(withMedia)
}

function filterOOS(options) {
  const showOOS = getConfigOptions('variant.show.oos')

  if (showOOS) {
    return options
  }

  return options.filter(option => ORDERABLE_STATES.includes(option.availability))
}

function formatDetailOptions(options) {
  return filterOOS(options)
    .map(option => {
      if (option.options) {
        return {
          ...option,
          options: formatDetailOptions(option.options)
        }
      }

      return option
    })
}

function formatPrices(product) {
  const { price = {}, priceMax, priceMin, priceRange } = product
  const { list = {}, sale = {} } = price
  const { listMin, listMax, saleMin, saleMax } = priceRange

  return {
    price: {
      list: {
        duty: list.duty,
        tax: list.tax,
        total: list.total
      },
      sale: {
        duty: sale.duty,
        tax: sale.tax,
        total: sale.total
      }
    },
    priceRange: {
      min: priceMin,
      max: priceMax,
      listMin: listMin,
      listMax: listMax,
      saleMin: saleMin,
      saleMax: saleMax
    }
  }
}

function sortCategories(categories) {
  return [...categories].sort((a, b) => a.length - b.length)
}

function getSizeAndFit(selectedVariant) {
  const { clientProperties = {} } = selectedVariant
  const { sizeAndFit } = clientProperties

  if (sizeAndFit) {
    const removeNull = sizeAndFit.map(row => row.filter((cell, i) => {
      if (i === 0 || cell) {
        return true
      }
    }))

    return removeNull
  }

  return undefined
}

function getPriceBreakdown(selectedVariant) {
  // custom data for Everlane's transparency pricing
  const { clientProperties = {} } = selectedVariant
  const { priceBreakdown } = clientProperties

  if (!priceBreakdown) return undefined

  /* eslint-disable camelcase */
  const { icon, costs, true_cost, price } = priceBreakdown

  if (
    !icon ||
    !costs ||
    !true_cost ||
    !price
  ) return undefined

  const { duties, hardware, labor, materials, transport } = costs

  if (
    !materials ||
    !hardware ||
    !labor ||
    !duties ||
    !transport
  ) return undefined

  return {
    icon,
    costs,
    trueCost: true_cost,
    price
  }

  /* eslint-enable camelcase */
}
