import React from 'react'

import { I18n } from 'utils/i18n'

import { Layout } from 'components/layout'
import { Heading, HtmlText } from 'components/text'

import styles from './scaffold-error.css'

export const webLabels = {
  title: `WEB.CHECKOUT.FORM.ERROR.GENERIC.TITLE`,
  description: `WEB.CHECKOUT.FORM.ERROR.GENERIC.DESCRIPTION`
}

export const ScaffoldError = () => (
  <div className={styles.scaffoldError}>
    <Layout
      className={styles.scaffoldContainer}
      layout={{
        container: `clientDefault`
      }}
    >
      <Heading
        content={{
          html: I18n.t(webLabels.title)
        }}
        styling={{
          fontFamily: `primary`,
          fontSize: `large`,
          universalColor: true
        }}
      />
      <HtmlText html={I18n.t(webLabels.description)} />
    </Layout>
  </div>
)
