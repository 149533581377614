import React from 'react'

import { useDataFetching } from 'hooks'
import { augmentation } from 'utils/augmentation'
import { Factory } from 'components/factory'

export const ComponentTemplate = ({
  items, // augmentation items
  template
}) => {
  const [file, triedFetch] = useDataFetching({
    folder: `/themes/templates`,
    source: `${template}.json`
  })

  if (!triedFetch) {
    return (
      <div />
    )
  }

  if (file) {
    return (
      <Factory items={augmentation(file.items, items)} />
    )
  }

  return null
}
