import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { closePullout, overlayOff } from 'state/actions'
import { I18n } from 'utils/i18n'
import { useFilters } from 'hooks'
import { Layout } from 'components/layout'
import { FilterBellow, SelectedFilters } from 'components/filters'
import { Heading } from 'components/text'
import { Button } from 'components/buttons'

import styles from './mobile-filters.css'

export const webLabels = {
  apply: `WEB.LISTING.FILTER.CTA.APPLY`,
  title: `WEB.LISTING.FILTER.TITLE`
}

export const MobileFilters = () => {
  const { applyPending, removeUnavailableFilters } = useFilters()
  const pendingFilters = useSelector(state => state.listing.pendingFilters)
  const selectedFilters = useSelector(state => state.listing.selectedFilters)
  const dispatch = useDispatch()

  let filters = [
    {
      buttonType: `tickableOption`,
      name: 'brand',
      limit: 10,
      type: `list`
    },
    {
      name: 'size',
      categorize: true,
      type: 'grid',
      columns: 2,
      buttonType: 'selectableBox'
    },
    {
      name: 'color',
      type: 'grid',
      categorize: false,
      columns: 2,
      buttonType: 'tickableOption',
      showSwatch: true,
      swatchSize: 'normal'
    },
    {
      name: 'priceRange',
      type: 'rangeSelector'
    }
  ]

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          <Layout
            layout={{
              marginBottom: `xxs`
            }}
          >
            <Heading
              content={{
                html: I18n.t(webLabels.title)
              }}
              styling={{
                fontFamily: `primary`,
                fontSize: `xsRegular`,
                universalColor: true
              }}
            />
          </Layout>
        </div>
        <SelectedFilters />
      </div>
      {filters
        .filter(removeUnavailableFilters)
        .map(filter => (
          <FilterBellow
            buttonClass={styles.button}
            filter={filter}
            key={filter.name}
            optionsClass={styles.optionsWrapper}
            usePending
          />
        ))}
      <div className={styles.applyWrapper}>
        <Button
          disabled={checkForDisabled()}
          onClick={handleClick}
          positioning={{
            buttonHeight: `large`,
            buttonWidth: `full`
          }}
          styling={{
            singleLine: true,
            type: `primary`
          }}
        >
          {I18n.t(webLabels.apply)}
        </Button>
      </div>
    </div>
  )

  function handleClick() {
    dispatch(overlayOff('pullout'))
    dispatch(closePullout())
    applyPending()
  }

  function checkForDisabled() {
    const sortedPending = sortFilters(pendingFilters)
    const sortedSelected = sortFilters(selectedFilters)

    return isEqual(sortedPending, sortedSelected)
  }

  function sortFilters(target) {
    let build = {}

    Object.entries(target).forEach(([key, value]) => {
      build[key] = value.map(opt => opt).sort()
    })

    return build
  }
}
