import React from 'react'

import { useContent } from 'hooks'

import { Svg } from 'components/icons'
import { Image } from 'components/images'
import { HtmlText } from 'components/text'

import styles from './shipping-promo-box.css'

export const ShippingPromoBox = () => {
  const shippingPromotions = useContent('promotions').shipping.basket

  return (
    <div className={styles.boxWrapper}>
      {shippingPromotions.map((item) => (
        <div
          className={styles.itemWrapper}
          key={item.key}
        >

          {item.content.iconRef &&
            <div className={styles.iconWrapper}>
              <Svg
                block
                icon={item.content.iconRef}
                size={item.styling.iconSize}
              />
            </div>
          }

          {item.content.imageSrc &&
            <div className={styles.imageWrapper}>
              <Image src={item.content.imageSrc} />
            </div>
          }

          {item.content.html &&
            <HtmlText html={item.content.html} />
          }

        </div>
      ))
      }
    </div>
  )
}
