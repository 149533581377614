import React from 'react'

import { Link } from 'components/text'

import styles from './link-list.css'

const LinkList = ({ list }) => (
  <div>
    {
      list.map(item => (
        <div key={`link-${item.value}`}>
          <Link
            to={item.url}
            className={styles.underline}
          >
            {item.value}
          </Link>
        </div>
      ))
    }
  </div>
)

export { LinkList }
