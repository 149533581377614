import React from 'react'
import { Link as InflexibleReactRouterLink } from 'react-router-dom'
import { animateScroll } from 'react-scroll'
import classNames from 'classnames'

export const Link = React.forwardRef(function Link({
  to,
  children,
  className,
  onClick,
  ...args
}, ref) {
  const props = {
    className: classNames({ [className]: className }),
    onClick: handleClick,
    ref,
    ...args
  }

  if (!to) {
    return (
      <span {...props}>
        {children}
      </span>
    )
  }

  if (to.substring(0, 1) === '#') {
    return (
      <button {...props}>
        {children}
      </button>
    )
  }

  if (to.indexOf('//') > -1 || to.indexOf('mailto:') > -1) {
    return (
      <a
        {...props}
        href={to}
      >
        {children}
      </a>
    )
  }

  return (
    <InflexibleReactRouterLink
      {...props}
      to={to}
    >
      {children}
    </InflexibleReactRouterLink>
  )

  function handleClick() {
    window.$HISTORY.push(window.location.pathname)

    if (to.substring(0, 1) === '#') {
      const elem = document.querySelector(to)
      if (elem) {
        animateScroll.scrollTo(elem.offsetTop)
      }
    }

    onClick && onClick()
  }
})
