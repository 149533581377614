import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'

import { createReducerManager } from 'state/reducerManager'

import brands from './brands'
import cart from './cart'
import components from './components'
import details from './details'
import favorites from './favorites'
import language from './language'
import listing from './listing'
import navigation from './navigation'
import overlay from './overlay'
import pullout from './pullout'
import schemas from './schemas'
import site from './site'
import userPreferences from './user-preferences'

export const middleware = [
  promiseMiddleware(),
  thunk
]

export const staticReducers = {
  brands,
  cart,
  components,
  details,
  favorites,
  language,
  listing,
  navigation,
  overlay,
  pullout,
  schemas,
  site,
  userPreferences
}

export const reducerManager = createReducerManager(staticReducers)
// Create a store with the root reducer function being the one exposed by the manager.
const store = createStore(
  reducerManager.reduce,
  composeWithDevTools(
    applyMiddleware(...middleware)
  )
)

// Put the reducer manager on the store so it is easily accessible
store.reducerManager = reducerManager

export default store
