import React from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'

import { Svg } from 'components/icons'

import styles from './dropdown.css'

class DropdownClass extends React.Component {
  componentDidMount() {
    const {
      dispatch,
      input,
      initialValue,
      meta,
      options,
      useLabelAsValue
    } = this.props

    let startingValue = useLabelAsValue ? options[0].label : options[0].value

    if (initialValue) {
      startingValue = initialValue
    }

    if (!input.value) {
      dispatch(change(meta.form, input.name, startingValue))
    }
  }

  render() {
    const {
      input,
      name,
      options,
      disabled = false,
      // TO DO: use the region codes instead of just the strings
      useLabelAsValue
    } = this.props

    return (
      <React.Fragment>
        <select
          className={styles.inputContainer}
          id={name}
          disabled={disabled}
          {...input}
        >
          {options.map(option =>
            <option
              key={option.value ? option.value : Math.random()}
              value={useLabelAsValue ? option.label : option.value}
            >
              {option.label}
            </option>
          )}
        </select>
        <span className={styles.icon}>
          <Svg icon="angle_down" />
        </span>
      </React.Fragment>
    )
  }
}

export const Dropdown = connect()(DropdownClass)
