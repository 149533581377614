import React from 'react'
import { Breadcrumbs } from 'components/breadcrumbs'
import { Button } from 'components/buttons'

import {
  CategoryCards,
  ComponentTemplate,
  EmailSignup,
  Group,
  PageNav,
  ProductTray,
  Text,
  TextBanner,
  TextAndImage
} from 'factory-components'

import {
  DropdownFilters,
  SidebarFilters,
  MobileFiltersToggles,
  Paginator,
  ResultsSummary,
  SelectedFilters
} from 'components/filters'

import { Headline } from 'components/text'
import { Listing, ListingProductGrid } from 'components/product-listing'
import { PromoStrip } from 'components/header'
import { SideNavigation } from 'components/side-navigation'

import { MarkDownContent } from 'components/markdown/mark-down-content'

const LazyContactContainer = React.lazy(() => (
  import(/* webpackChunkName: "contact-container" */ 'factory-components/contact-form/contact-container')
))

const ContactContainer = props => (
  <React.Suspense fallback={<div />}>
    <LazyContactContainer {...props} />
  </React.Suspense>
)

export function universalComponents() {
  return {
    // Lithos 5.0 and ready to go
    button: Button,
    group: Group,
    headline: Headline,
    h1: Headline,
    productTray: ProductTray,
    componentTemplate: ComponentTemplate,
    textAndImage: TextAndImage,
    text: Text,
    categoryCards: CategoryCards,

    // Need to die
    promoStrip: PromoStrip, // used
    textBanner: TextBanner,

    // General
    breadcrumbs: Breadcrumbs,
    emailSignup: EmailSignup,

    // PLP
    dropdownFilters: DropdownFilters,
    sidebarFilters: SidebarFilters,
    listing: Listing,
    listingProductGrid: ListingProductGrid,
    mobileFiltersToggles: MobileFiltersToggles,
    results: ResultsSummary,
    paginator: Paginator,
    selectedFilters: SelectedFilters,
    sideNavigation: SideNavigation,

    // Support
    contactForm: ContactContainer,
    markDownContent: MarkDownContent,
    pageNav: PageNav
  }
}
