import React from 'react'
import classNames from 'classnames'
import merge from 'lodash/merge'

import { getConfigOptions } from 'global-content'

import { SkeletonImage, SkeletonText } from 'components/skeleton'

import shared from './skeleton.css'
import styles from './skeleton-card.css'

export const SkeletonCard = ({ options }) => {
  const {
    show: { brand },
    imageRatio
  } = getConfigOptions(`variant`)

  const defaultOptions = {
    showBrand: brand,
    showPrice: true,
    showSwatches: true,
    showTitle: true,
    textAlign: 'left'
  }

  const mergedOptions = merge({}, defaultOptions, options)

  const {
    showBrand,
    showPrice,
    showSwatches,
    showTitle,
    textAlign
  } = mergedOptions

  return (
    <div>
      <div className={classNames(shared.skeleton, styles.imageWrapper)}>
        <SkeletonImage ratio={imageRatio} />
      </div>

      <div className={styles[textAlign]}>
        {showBrand &&
          <SkeletonText
            margin="0 0 1rem 0"
            size="headingXS"
            width="50%"
          />
        }

        {showTitle &&
          <SkeletonText
            margin="0 0 1rem 0"
            size="linkSmall"
            width="75%"
          />
        }

        {showPrice &&
          <SkeletonText
            margin="0 0 0.3rem 0"
            size="bodySmall"
            width="60%"
          />
        }

        {showSwatches &&
          <SkeletonText
            margin="0 0 0.8rem 0"
            size="bodySmall"
            width="50%"
          />
        }
      </div>
    </div>
  )
}
